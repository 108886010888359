import React, { useEffect, useState } from "react";
import { CommonNavV3 } from "./CommonNavV3";
import { RangeShower } from "./RangeShower";
import { CommonFooterV3 } from "./CommonFooterV3";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CheckOutTimeSlotV3 from "./CheckOutTimeSlotV3";

export const ChooseSlots = () => {
  const store = useSelector((state) => state);
  const nav = useNavigate();
  const { periodType, pageType } = useParams();
  const dispatch = useDispatch();
  //   console.log(store.checktoken_data.selectedlab.slots);
  const [BookTime, setBookTime] = useState("");
  const [BookDate, setBookDate] = useState(new Date());
  const [ListOfTimewithDate, setListOfTimewithDate] = useState([]);
  const [OverAllDateTime, setOverAllDateTime] = useState({
    date: new Date(),
    time: "",
    index: 0,
  });
  const [BookIndexDate, setBookIndexDate] = useState(0);
  useEffect(() => {
    setListOfTimewithDate(store?.checktoken_data?.selectedlab?.dates || []);
    setBookDate(store?.checktoken_data?.selectedlab?.dates[0]?.date);
  }, [store?.checktoken_data?.selectedlab?.dates]);
  // useEffect(() => {
  //   if (store?.checktoken_data?.selectedlab?.choose_slot) {
  //     setBookTime(store?.checktoken_data?.selectedlab?.choose_slot?.time);
  //     setBookIndexDate(store?.checktoken_data?.selectedlab?.choose_slot?.index);
  //     setBookDate(store?.checktoken_data?.selectedlab?.choose_slot?.date);
  //     setOverAllDateTime(store?.checktoken_data?.selectedlab?.choose_slot);
  //   }
  // }, [store?.checktoken_data?.selectedlab?.choose_slot]);
  //

  useEffect(() => {
    if (!store?.checktoken_data?.selectedlab?.labName) {
      nav("/bookingslot/period-question/question");
    }
  }, []);
  return (
    <>
      {/* <CommonNavV3
        {...{
          goback: () => {
            nav(`/bookingslot/${periodType}/labs`);
          },
        }}
      /> */}
      <div className="choose_slots_section">
        <RangeShower
          {...{
            list: 4,
            current: 3,
          }}
        />
        <div className="choose_slots_title">Choose a date & time</div>
        <div className="choose_slots_desc">
          Fast for 10-12 hours before your appointment. Only water is allowed
          during this time.
        </div>
        <div className="slots_list">
          <CheckOutTimeSlotV3
            {...{
              BookTime,
              setBookTime,
              ListOfTimewithDate,
              setBookDate,
              BookDate,
              setOverAllDateTime,
              BookIndexDate,
              setBookIndexDate,
            }}
          />
        </div>
      </div>
      <CommonFooterV3
        {...{
          name: "Select date & time",
          onclick: () => {
            if (OverAllDateTime.time === "") {
              return;
            }

            dispatch({
              type: "setCheckToken",
              payload: {
                ...store.checktoken_data,
                selectedlab: {
                  ...store.checktoken_data.selectedlab,
                  choose_slot: OverAllDateTime,
                },
              },
            });

            nav(`/bookingslot/${periodType}/confirmation`);
            // if (order1 && order2) {
            // } else {
            //   nav(`/bookingslot/period-now/labs`);
            // }
          },
        }}
      />
    </>
  );
};
