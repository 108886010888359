import React, { useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { CommonNavV3 } from "./CommonNavV3";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CommonFooterV3 } from "./CommonFooterV3";
import { RangeShower } from "./RangeShower";
import { date_formatter } from "../../component/CustInput/CustInput";
import { dateFormatter } from "../../component/API/new_api";

export const Reminder = () => {
  const nav = useNavigate();
  const store = useSelector((state) => state);
  const { periodType, pageType } = useParams();
  const dispatch = useDispatch();
  const [MiniDate, setMiniDate] = useState(new Date());
  const [FurtherDate, setFurtherDate] = useState(new Date());
  const [err_pagevalues, seterr_pagevalues] = useState({
    menstralstate: "Select",
    date_time: false,
    zipcode: "",
  });
  useEffect(() => {
    if (store?.checktoken_data?.selectedlab?.reminderdate) {
      setFurtherDate(store?.checktoken_data?.selectedlab.reminderdate);
    }
  }, [store?.checktoken_data?.selectedlab?.reminderdate]);

  useEffect(() => {
    if (
      !store?.checktoken_data?.selectedlab?.menstralstate
      // booking_data?.product_id?.toLowerCase == "ft02"
    ) {
      nav("/bookingslot/period-question/question");
    }
  }, [store?.checktoken_data?.selectedlab?.menstralstate]);

  const handle_errpagevalues = (updated_data) => {
    return seterr_pagevalues((prev) => ({ ...prev, ...updated_data }));
  };

  return (
    <>
      {/* <CommonNavV3
        {...{
          goback: () => {
            nav(`/bookingslot/period-question/question`);
          },
        }}
      /> */}

      <div className="reminder_section">
        <RangeShower
          {...{
            list: 3,
            current: 2,
          }}
        />
        <div className="reminder_title">
          When is your next period start date?
        </div>
        <div className="reminder_desc">
          You can set an approximate date. We'll send you a reminder a few days
          before.
        </div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            minDate={dayjs(
              date_formatter(new Date(MiniDate), "year_long-month-date")
            )}
            sx={{
              ".Mui-selected": {
                backgroundColor: "var(--base) !important",
              },
            }}
            maxDate={dayjs(
              date_formatter(
                new Date(
                  "Wed Sep 02 2093 00:00:00 GMT+0530 (India Standard Time)"
                ),
                "year_long-month-date"
              )
            )}
            value={
              FurtherDate
                ? dayjs(
                    date_formatter(
                      new Date(FurtherDate),
                      "year_long-month-date"
                    )
                  )
                : FurtherDate
            }
            onChange={(value) => {
              setFurtherDate(value);
              handle_errpagevalues({
                date_time:
                  !value ||
                  value.$d == "Invalid Date" ||
                  value?.$d == "Invalid Date"
                    ? value?.$d
                    : date_formatter(
                        new Date(value?.$d),
                        "year_long-month-date"
                      ) < new Date(MiniDate).toISOString().split("T")[0],
              });
            }}
          />
        </LocalizationProvider>
      </div>
      <CommonFooterV3
        {...{
          name: "Select",
          onclick: () => {
            if (
              FurtherDate.$d == "Invalid Date" ||
              date_formatter(
                FurtherDate.$d || FurtherDate,
                "year_long-month-date"
              ) < date_formatter(MiniDate, "year_long-month-date")
            ) {
              return;
            }
            dispatch({
              type: "setCheckToken",
              payload: {
                ...store.checktoken_data,
                selectedlab: {
                  ...store.checktoken_data.selectedlab,
                  reminderdate: FurtherDate,
                },
              },
            });
            nav(`/bookingslot/${periodType}/confirmation`);
            // if (order1 && order2) {
            // } else {
            //   nav(`/bookingslot/period-now/labs`);
            // }
          },
        }}
      />
    </>
  );
};
