import React, { useEffect, useState } from "react";
import CustInputMuiClone from "../../component/CustInput/CustInputMuiClone";
import { CheckPincodeV2, CheckPincodeV3 } from "../../component/API/api";
import { getAddress, UpdateUserAddress } from "../../component/API/new_api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

export const EditAddressV3 = ({ EditAddressModal, setEditAddressModal }) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { periodType, pageType } = useParams();
  const nav = useNavigate();
  const [PincodeServisable, setPincodeServisable] = useState("");
  const [ValidZipCodeOrnot, setValidZipCodeOrnot] = useState(false);
  const [HandlerAddresError, setHandlerAddresError] = useState({
    apt: "",
    city: "",
    landmark: "",
    address: "",
  });
  const [ModalValue, setModalValue] = useState({
    apt: "",
    city: "",
    zipcode: "",
    address: "",
    landmark: "",
    state: "",
  });
  const [addressdebo, setaddressdebo] = useState("");
  const [Err_ModalValue, setErr_ModalValue] = useState({
    apt: "",
    city: "",
    zipcode: "",
    address: "",
    landmark: "",
  });
  const [AddressList, setAddressList] = useState([]);
  const EditUpdateModalValue = () => {
    setModalValue((prev) => ({
      apt: store?.checktoken_data?.customer?.address?.apt,
      city: store?.checktoken_data?.customer?.address?.city,
      state: store?.checktoken_data?.customer?.address?.state,
      zipcode: store?.checktoken_data?.customer?.address?.pin_code,
      address: store?.checktoken_data?.customer?.address?.address,
      landmark: store?.checktoken_data?.customer?.address?.landmark,
    }));
  };
  console.log(store?.checktoken_data?.customer?.address);

  useEffect(() => {
    EditUpdateModalValue();
  }, []);

  useEffect(() => {
    if (ModalValue?.zipcode?.length === 6) {
      CheckPincodeV3(ModalValue.zipcode, (res) => {
        if (res.response.serviceable) {
          setValidZipCodeOrnot(true);
          setPincodeServisable("Pincode serviceable!");
          setModalValue((prev) => ({
            ...prev,
            city: res.response.district,
            state: res.response.state,
          }));
          handle_errpagevalues({
            zipcode: false,
          });
        } else {
          setPincodeServisable(
            "Sorry, this pincode is not serviceable at the moment. Try again, or we’ll reach out once it becomes available."
          );
          setValidZipCodeOrnot(false);
          handle_errpagevalues({
            zipcode: true,
          });
        }
        // if (res.response.msg === "Pincode Valid") {
        //   setValidZipCodeOrnot(true);
        //   setPincodeServisable("Pincode serviceable!");
        // } else if (
        //   res.response.msg === "Pincode InValid" ||
        //   res.response.msg === "Enter valid pincode"
        // ) {
        //   setPincodeServisable(
        //     "Sorry, this pincode is not serviceable at the moment. Try again, or we’ll reach out once it becomes available."
        //   );
        //   setValidZipCodeOrnot(false);
        //   handle_errpagevalues({
        //     zipcode: true,
        //   });
        // }
      });
    }
  }, [ModalValue.zipcode, store?.checktoken_data?.customer?.address?.pin_code]);
  const handle_pagevalues = (updated_data) => {
    setModalValue((prev) => ({ ...prev, ...updated_data }));
  };
  const handle_errpagevalues = (updated_data) => {
    return setErr_ModalValue((prev) => ({ ...prev, ...updated_data }));
  };
  const Ignore = (key, value) => {
    let inp = value
      .split("")
      .map((el) => {
        if (/^[a-zA-Z0-9\s,.\-#&]*$/i.test(el)) {
          setHandlerAddresError((prev) => ({
            ...prev,
            [key]: "",
          }));
          return el;
        } else {
          setHandlerAddresError((prev) => ({
            ...prev,
            [key]:
              "Only use letters, numbers, spaces, and these symbols: . , - # &.",
          }));
          return "";
        }
      })
      .join("");
    handle_pagevalues({
      [key]: inp,
    });
    handle_errpagevalues({
      [key]: inp ? "" : "Error message",
    });
  };

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (addressdebo !== "") {
        getAddress(
          addressdebo,
          (success) => {
            setAddressList(success.addresses);
          },
          (error) => {}
        );
      } else {
        setAddressList([]);
      }
    }, 300);

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if ModalValue.address changes before 300ms
    };
  }, [addressdebo]);

  return (
    <div
      className="edit_address_modal_v3"
      onClick={() => {
        setEditAddressModal(false);
      }}
    >
      <div
        className="edit_address_section"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="edit_address_section_title">Edit Address</div>
        <div className="inner_input_detail">
          <CustInputMuiClone
            {...{
              attr: {
                error: Err_ModalValue.zipcode,
                error_msg: "",
                value: ModalValue.zipcode,
                inputMode: "numeric",
                event: {
                  change: ({ target }) => {
                    if (Number(target.value.slice(-1)).toString() == "NaN") {
                      return;
                    }
                    if (target.value.length > 6) {
                      return;
                    }
                    setValidZipCodeOrnot(false);
                    setPincodeServisable("");
                    handle_pagevalues({
                      zipcode: target.value,
                    });
                    if (
                      store?.checktoken_data?.customer?.address?.pin_code !=
                      ModalValue.zipcode
                    ) {
                      handle_errpagevalues({
                        zipcode: true,
                      });
                    }
                    // handle_errpagevalues({
                    //   zipcode: target.value == "" ? true : false,
                    // });

                    if (target.value.length == 6) {
                    }
                  },
                },
              },
              data: {
                title: "Pincode",
              },
            }}
          />
          <div
            className={
              ValidZipCodeOrnot ? "v2_pincode_serv" : "v2_pincode_serv_not"
            }
          >
            {PincodeServisable}
          </div>
        </div>
        <div className="inner_input_detail">
          <CustInputMuiClone
            {...{
              attr: {
                error: Err_ModalValue.address || HandlerAddresError.address,
                error_msg: "",
                value: ModalValue.address,
                readonly: false,
                event: {
                  change: ({ target }) => {
                    Ignore("address", target.value);
                    setaddressdebo(target.value);
                  },
                },
              },
              data: {
                title: "Address",
              },
            }}
          />
          {AddressList.length ? (
            <div className="add_input_list">
              {AddressList?.map((item, index) => (
                <div
                  key={index}
                  onClick={() => {
                    Ignore("address", item);
                    setaddressdebo("");
                    setAddressList([]);
                  }}
                >
                  {item}
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
          <div className={"v2_pincode_serv_not"}>
            {HandlerAddresError.address}
          </div>
        </div>
        <div className="inner_input_detail">
          <CustInputMuiClone
            {...{
              attr: {
                error: Err_ModalValue.apt || HandlerAddresError.apt,
                error_msg: "",
                value: ModalValue.apt,
                readonly: false,
                event: {
                  change: ({ target }) => {
                    Ignore("apt", target.value);
                  },
                },
              },
              data: {
                title: "House , Apt , Floor",
              },
            }}
          />
          <div className={"v2_pincode_serv_not"}>{HandlerAddresError.apt}</div>
        </div>
        <div className="inner_input_detail">
          <CustInputMuiClone
            {...{
              attr: {
                error: Err_ModalValue.landmark || HandlerAddresError.landmark,
                error_msg: "",
                value: ModalValue.landmark,
                readonly: false,
                event: {
                  change: ({ target }) => {
                    Ignore("landmark", target.value);
                  },
                },
              },
              data: {
                title: "Landmark",
              },
            }}
          />
          <div className={"v2_pincode_serv_not"}>
            {HandlerAddresError.landmark}
          </div>
        </div>
        <div className="inner_input_detail">
          <CustInputMuiClone
            {...{
              attr: {
                error: Err_ModalValue.city || HandlerAddresError.city,
                error_msg: "",
                value: ModalValue.city,
                readonly: true,
                event: {
                  change: ({ target }) => {},
                },
              },
              data: {
                title: "City",
              },
            }}
          />
          <div className={"v2_pincode_serv_not"}>{HandlerAddresError.city}</div>
        </div>
        <div className="inner_input_detail">
          <CustInputMuiClone
            {...{
              attr: {
                error: Err_ModalValue.city || HandlerAddresError.city,
                error_msg: "",
                value: ModalValue.state,
                readonly: true,
                event: {
                  change: ({ target }) => {},
                },
              },
              data: {
                title: "State",
              },
            }}
          />
          <div className={"v2_pincode_serv_not"}>{HandlerAddresError.city}</div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            className={
              "save_address " +
              (!(
                (store?.checktoken_data?.customer?.address?.apt.trim("") !=
                  ModalValue.apt.trim("") ||
                  store?.checktoken_data?.customer?.address?.landmark.trim(
                    ""
                  ) != ModalValue.landmark.trim("") ||
                  store?.checktoken_data?.customer?.address?.address.trim("") !=
                    ModalValue.address.trim("") ||
                  store?.checktoken_data?.customer?.address?.pin_code !=
                    ModalValue.zipcode) &&
                // store?.checktoken_data?.customer?.address?.city !=
                //   ModalValue.city) &&
                !Err_ModalValue.zipcode &&
                ModalValue.zipcode.length == 6 &&
                ModalValue.apt != "" &&
                ModalValue.landmark != "" &&
                ModalValue.address != ""
              )
                ? "save_address_d_active"
                : "")
            }
            onClick={() => {
              if (
                !(
                  (store?.checktoken_data?.customer?.address?.apt !=
                    ModalValue.apt ||
                    store?.checktoken_data?.customer?.address?.landmark !=
                      ModalValue.landmark ||
                    store?.checktoken_data?.customer?.address?.address !=
                      ModalValue.address ||
                    store?.checktoken_data?.customer?.address?.pin_code !=
                      ModalValue.zipcode) &&
                  //   ||
                  // store?.checktoken_data?.customer?.address?.city !=
                  //   ModalValue.city
                  !Err_ModalValue.zipcode &&
                  ModalValue.zipcode.length == 6 &&
                  ModalValue.apt != "" &&
                  ModalValue.landmark != "" &&
                  ModalValue.address != ""
                )
              ) {
                return;
              }
              if (
                // !ModalValue.city ||
                !ModalValue.apt ||
                !ModalValue.landmark ||
                !ModalValue.address ||
                ModalValue.zipcode.length != 6
              ) {
                setErr_ModalValue((prev) => ({
                  ...prev,
                  apt: !ModalValue.apt,
                  // city: !ModalValue.city,
                  zipcode: ModalValue.zipcode.length != 6,
                  address: !ModalValue.address,
                  landmark: !ModalValue.landmark,
                }));
                return;
              }

              UpdateUserAddress(
                store.checktoken_data.token,
                {
                  pin_code: ModalValue.zipcode,
                  address: ModalValue.address,
                  apt: ModalValue.apt,
                  landmark: ModalValue.landmark,
                  city: ModalValue.city || "",
                  state: ModalValue.state || "",
                },
                (success) => {
                  if (pageType == "confirmation") {
                    dispatch({
                      type: "setCheckToken",
                      payload: {
                        ...store.checktoken_data,
                        customer: {
                          ...store.checktoken_data.customer,
                          address: {
                            pin_code: ModalValue.zipcode,
                            address: ModalValue.address,
                            apt: ModalValue.apt,
                            landmark: ModalValue.landmark,
                            city: ModalValue.city,
                            state: ModalValue.state,
                          },
                        },
                        selectedlab: {
                          menstralstate:
                            store.checktoken_data.selectedlab.menstralstate,
                        },
                      },
                    });
                    nav(`/bookingslot/${periodType}/labs`);
                  } else {
                    dispatch({
                      type: "setCheckToken",
                      payload: {
                        ...store.checktoken_data,
                        customer: {
                          ...store.checktoken_data.customer,
                          address: {
                            pin_code: ModalValue.zipcode,
                            address: ModalValue.address,
                            apt: ModalValue.apt,
                            landmark: ModalValue.landmark,
                            city: ModalValue.city,
                            state: ModalValue.state,
                          },
                        },
                      },
                    });
                  }
                  setEditAddressModal(false);
                },
                (err) => {}
              );
            }}
          >
            Save
          </div>
          <div
            className={"save_address"}
            style={{
              background: "#fff",
              color: "#000",
            }}
            onClick={() => {
              setEditAddressModal(false);
            }}
          >
            Cancel
          </div>
        </div>
      </div>
    </div>
  );
};
