import React from "react";
import { useNavigate } from "react-router-dom";
import { CommonNavV3 } from "./CommonNavV3";
import { CommonFooterV3 } from "./CommonFooterV3";

export const CoverSlide = () => {
  const nav = useNavigate();
  return (
    <>
      {/* <CommonNavV3
        {...{
          goback: () => {
            nav(-1);
          },
        }}
      /> */}
      <div className="cover_slide_section">
        <div className="cover_slide_inner">Schedule your sample collection</div>
      </div>
      <CommonFooterV3
        {...{
          name: "Start",
          onclick: () => {
            nav(`/bookingslot/period-question/question`);
            // if (order1 && order2) {
            // } else {
            //   nav(`/bookingslot/period-now/labs`);
            // }
          },
        }}
      />
    </>
  );
};
