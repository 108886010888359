import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
// import "react-date-range/dist/styles.css"; // main css file
// import "react-date-range/dist/theme/default.css"; // theme css file
// import { DateRangePicker } from "react-date-range";
import axios from "axios";
import { Table } from "../component/Table";
const percentageDiff = (v1, v2) => {
  if (v1 == 0 || v1 == v2) {
    return {
      og: 0,
      value: 0,
      sign: false,
    };
  }
  // if()
  let value = (v2 / v1) * 100;
  value = isNaN(value) ? 0 : value;
  if (isFinite(value)) {
    return value > 100
      ? {
          og: value,
          value: (value - 100).toFixed(2),
          sign: false,
        }
      : {
          og: value,
          value: (100 - value).toFixed(2),
          sign: value ? true : false,
        };
  } else {
    return value > 0
      ? {
          og: value,
          value: 100,
          sign: false,
        }
      : {
          og: value,
          value: 100,
          sign: true,
        };
  }
};
const data = [
  {
    selected: {
      date: "20 May",
      value: 0,
      show: true,
    },
    previous: {
      date: "20 Apr",
      value: 0,
      show: true,
    },
    name: "12",
  },
];
const paddNumbers = (num, size) => String(num).padStart(size, "0");
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const monthShortNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const dateFormatter = (dateInput, format) => {
  const date = dateInput ? new Date(dateInput) : new Date();

  const hours24 = date.getHours();
  const hours12 = hours24 % 12 || 12;
  const ampm = hours24 >= 12 ? "PM" : "AM";

  const options = {
    YYYY: date.getFullYear(),
    MM: date.getMonth() + 1,
    MMF: monthNames[date.getMonth()],
    MMS: monthShortNames[date.getMonth()],
    DD: date.getDate(),
    HH: hours24,
    hh: hours12,
    mm: date.getMinutes(),
    ss: date.getSeconds(),
    A: ampm,
    WKL: date.toLocaleDateString("en-US", { weekday: "long" }),
    WKS: date.toLocaleDateString("en-US", { weekday: "short" }),
  };

  return format.replace(/YYYY|MMF|MMS|MM|DD|WKS|WKL|HH|hh|mm|ss|A/g, (match) =>
    paddNumbers(options[match], 2)
  );
};
const def_date_filters = ["Today", "7D", "30D", "3M", "All"];
const Reports = () => {
  const [graph_width, setgraph_width] = useState(500);
  const main_page = useRef();

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [page_data, setpage_data] = useState({
    current: {},
    previous: {},
  });
  const [date_filter, setdate_filter] = useState(def_date_filters[0]);
  const [show_date_filter, setshow_date_filter] = useState(false);
  const [graph_data, setgraph_data] = useState([]);
  useLayoutEffect(() => {
    setgraph_width(main_page.current.getBoundingClientRect().width - 50);
  }, []);
  const [line_to_show, setline_to_show] = useState(0);
  const [points, setpoints] = useState(0);
  const [products, setproducts] = useState([]);
  //
  const getGraphData = () => {
    let data = JSON.stringify({
      // start: dateFormatter(state[0].startDate, "MM/DD/YYYY"),
      // end: dateFormatter(state[0].endDate, "MM/DD/YYYY"),
      // points: points,
      // name: t_points[1],
      option: date_filter,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://reports-api.arva.health/reports",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then(({ data: { status, plot, current, previous } }) => {
        if (plot) {
          setgraph_data(plot);
          setpoints(plot.length);
          setpage_data({
            current: {
              ...current,
            },
            previous,
            percentages: {
              averageOrderValue: percentageDiff(
                previous?.averageOrderValue || 0,
                current?.averageOrderValue || 0
              ),
              totalOrders: percentageDiff(
                previous?.totalOrders || 0,
                current?.totalOrders || 0
              ),
              totalRevenue: percentageDiff(
                previous?.totalRevenue || 0,
                current?.totalRevenue || 0
              ),
              conversionRate: percentageDiff(
                previous?.conversionRate || 0
                // current?.conversionRate || 0
              ),
            },
          });
          let sku_prod = {};
          current.products.map(
            (el) =>
              (sku_prod[el.sku] = {
                sku: el.sku,
                product: el.name,
                current: {
                  revenue: el?.revenue || 0,
                  count: el?.count || 0,
                  conversionRate: el?.conversionRate || 0,
                },
              })
          );
          previous.products.map(
            (el) =>
              (sku_prod[el.sku] = {
                ...sku_prod[el.sku],

                sku: sku_prod[el.sku]?.sku || el.sku,
                product: sku_prod[el.sku]?.product || el.product,
                previous: {
                  revenue: el?.revenue || 0,
                  count: el?.count || 0,
                  conversionRate: el?.conversionRate || 0,
                },
                percentages: {
                  revenue: percentageDiff(
                    el?.revenue || 0,
                    sku_prod[el.sku]?.current?.revenue || 0
                  ),
                  orders: percentageDiff(
                    el?.count || 0,
                    sku_prod[el.sku]?.current?.count || 0
                  ),
                  conversionRate: percentageDiff(
                    el?.conversionRate || 0,
                    sku_prod[el.sku]?.current?.conversionRate || 0
                  ),
                },
              })
          );

          setproducts(Object.values(sku_prod));
        }
        //
      })
      .catch((error) => {
        //
      });
  };
  useEffect(() => {
    // let t_points = calculatePoints(state[0].startDate, state[0].endDate);
    //
    // let diff= start
    getGraphData();
  }, [
    date_filter,
    // state
  ]);
  console.table(products);
  return (
    <div
      className="reports_page "
      ref={main_page}
      onClick={() => setshow_date_filter(false)}
    >
      {/* <div className="page_title">Reports</div> */}
      <div className="headers">
        <div className="data_controllers">
          {def_date_filters.map((el) => (
            <div
              active={el == date_filter ? "true" : ""}
              onClick={() => setdate_filter(el)}
            >
              {el}
            </div>
          ))}
          {/* <div
            className="date_viewer"
            onClick={(e) => {
              e.stopPropagation();
              setshow_date_filter((prev) => !prev);
            }}
          >
            {dateFormatter(state[0].startDate, "MMS DD, YYYY") +
              " - " +
              dateFormatter(state[0].endDate, "MMS DD, YYYY")}
            <div
              className="date_picker"
              active={show_date_filter ? "true" : ""}
              onClick={(e) => e.stopPropagation()}
            >
              <DateRangePicker
                onChange={(item) => {
                  setpoints(0);
                  setState([item.selection]);
                }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={state}
                direction="horizontal"
              />
            </div>
          </div> */}
        </div>
      </div>
      <div className="header1">
        <div className="card">
          <div className="header">
            <div className="txt">Total revenue</div>
            <div
              className={
                "chip " +
                (page_data?.percentages?.totalRevenue?.sign ? "low" : "high")
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M9 7.5L6 4.5L3 7.5"
                  stroke="var(--svg_color)"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {page_data?.percentages?.totalRevenue?.value || 0}%
            </div>
          </div>
          <div className="body">
            <div className="amount">
              ₹{page_data?.current?.totalRevenue || 0}{" "}
              <span>
                vs ₹{page_data?.previous?.totalRevenue || 0} last period
              </span>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="header">
            <div className="txt">Total orders</div>
            <div
              className={
                "chip " +
                (page_data?.percentages?.totalOrders?.sign ? "low" : "high")
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M9 7.5L6 4.5L3 7.5"
                  stroke="var(--svg_color)"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {page_data?.percentages?.totalOrders?.value || 0}%
            </div>
          </div>
          <div className="body">
            <div className="amount">
              {page_data?.current?.totalOrders || 0}{" "}
              <span>
                vs {page_data?.previous?.totalOrders || 0} last period
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* <input
        value={points}
        onChange={({ target: { value } }) => setpoints(value)}
      /> */}
      {/* <button onClick={() => getGraphData()}>Set</button> */}
      <Graph {...{ graph_width, line_to_show, setline_to_show, graph_data }} />
      <div className="header1">
        <div className="card">
          <div className="header">
            <div className="txt">Avg. order value</div>
            <div
              className={
                "chip " +
                (page_data?.percentages?.averageOrderValue?.sign
                  ? "low"
                  : "high")
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M9 7.5L6 4.5L3 7.5"
                  stroke="var(--svg_color)"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {page_data?.percentages?.averageOrderValue?.value || 0}%
            </div>
          </div>
          <div className="body">
            <div className="amount">
              ₹{page_data?.current?.averageOrderValue || 0}{" "}
              <span>
                vs ₹{page_data?.previous?.averageOrderValue || 0} last period
              </span>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="header">
            <div className="txt">Conversion rate</div>
            <div
              className={
                "chip " +
                (page_data?.percentages?.conversionRate?.sign ? "low" : "high")
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M9 7.5L6 4.5L3 7.5"
                  stroke="var(--svg_color)"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {page_data?.percentages?.conversionRate?.value || 0}%
            </div>
          </div>
          <div className="body">
            <div className="amount">
              {page_data?.current?.conversionRate || 0}%{" "}
              <span>
                vs {page_data?.previous?.conversionRate || 0}% last period
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="products">Products</div>
      <Table
        {...{
          cust_class: "products_table",
          header: ["SKU", "PRODUCT", "REVENUE", "ORDERS", "CONVERSION RATE"],
          body: products.map((el) => [
            el?.sku || "",
            el?.product,
            <div className="revenue_group">
              <div className="txt">
                ₹{el?.current?.revenue || 0}{" "}
                <span>vs ₹{el?.previous?.revenue || 0}</span>
              </div>
              <div
                className={
                  "chip " + (el?.percentages?.revenue?.sign ? "low" : "high")
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M9 7.5L6 4.5L3 7.5"
                    stroke="var(--svg_color)"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {el?.percentages?.revenue?.value || 0}%
              </div>
            </div>,
            <div className="revenue_group">
              <div className="txt">
                {el?.current?.count || 0}{" "}
                <span>vs {el?.previous?.count || 0}</span>
              </div>
              <div
                className={
                  "chip " + (el?.percentages?.orders?.sign ? "low" : "high")
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M9 7.5L6 4.5L3 7.5"
                    stroke="var(--svg_color)"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {el?.percentages?.orders?.value || 0}%
              </div>
            </div>,
            <div className="revenue_group">
              <div className="txt">
                {el?.current?.conversionRate || 0}%{" "}
                <span>vs {el?.previous?.conversionRate || 0}%</span>
              </div>
              <div
                className={
                  "chip " +
                  (el?.percentages?.conversionRate?.sign ? "low" : "high")
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M9 7.5L6 4.5L3 7.5"
                    stroke="var(--svg_color)"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {el?.percentages?.conversionRate?.value || 0}%
              </div>
            </div>,
          ]),
          // body: [],

          onrowclick: "",
        }}
      />
    </div>
  );
};

export default Reports;

const Graph = ({
  graph_width,
  line_to_show,
  setline_to_show,
  graph_data = [],
}) => {
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom_tooltip"
          // style={{
          //   visibility:
          //     payload[0].payload?.selected?.show ||
          //     payload[0].payload?.previous?.show
          //       ? "visible"
          //       : "hidden",
          // }}
        >
          <div className="intro">Revenue</div>
          <div className="divider"></div>
          {/* {payload[0].payload?.selected?.show && ( */}
          <div className="desc">
            <div className="amount">
              <div className="dot1"></div>₹{payload[0].payload.selected.value}
            </div>
            <div>{payload[0].payload.selected.display}</div>
          </div>
          {/* )}
          {payload[0].payload.previous.show && ( */}
          <div className="desc">
            <div className="amount">
              <div className="dot1 dot2"></div>₹
              {payload[0].payload.previous.value}
            </div>
            <div>{payload[0].payload.previous.display}</div>
          </div>
          {/* )} */}
        </div>
      );
    }

    return null;
  };
  return (
    <div className="graph">
      <LineChart width={graph_width || 500} height={300} data={graph_data}>
        <CartesianGrid
          strokeDasharray="10 10"
          horizontal={false}
          vertical={true}
        />
        <XAxis
          dataKey="name"
          padding={{ left: 0, right: 0 }}
          stroke="#777777"
        />
        <YAxis stroke="#777777" strokeDasharray="10 10" />
        <Tooltip content={<CustomTooltip />} />
        {/* <Legend /> */}
        <Line
          type="monotone"
          dataKey="selected.value"
          stroke={line_to_show != 2 ? "#FFA977" : "transparent"}
          strokeWidth={3}
          activeDot={{ r: 8 }}
          // hide={true}
          onMouseEnter={(e) => setline_to_show(1)}
          onMouseLeave={(e) => setline_to_show(0)}
        />

        <Line
          type="monotone"
          dataKey="previous.value"
          stroke={
            line_to_show != 1 ? "rgba(255, 169, 119, 0.50)" : "transparent"
          }
          strokeWidth={3}
          onMouseEnter={(e) => setline_to_show(2)}
          onMouseLeave={(e) => setline_to_show(0)}
        />
      </LineChart>
    </div>
  );
};
