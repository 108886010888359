import React, { useEffect, useState } from "react";
import { date_formatter } from "../../component/CustInput/CustInput";
import { dateFormatter } from "../Reports";

const CheckOutTimeSlotV3 = ({
  BookTime,
  setBookTime,
  ListOfTimewithDate,
  setBookDate,
  BookDate,
  setOverAllDateTime,
  BookIndexDate,
  setBookIndexDate,
}) => {
  // const timeSlot = [
  //   "5:30 - 6:30 am",
  //   "6:00 - 7:00 am",
  //   "6:30 - 7:30 am",
  //   "7:00 - 8:00 am",
  // ];

  // useEffect(() => {
  //   setBookDate(ListOfTimewithDate[0]?.date);
  // }, [ListOfTimewithDate]);

  return (
    <>
      <div className="your_time_slot_v3">
        <div className="your_date_slot">
          {ListOfTimewithDate?.slice(0, 3)?.map((el, i) => (
            <div
              key={i}
              className={
                "your_date_slot_box " +
                (new Date(BookDate)?.getDate()?.toString() ===
                new Date(el?.date)?.getDate()?.toString()
                  ? " your_date_slot_box_active"
                  : "")
              }
              onClick={() => {
                setBookDate(el?.date);
                setBookIndexDate(i);
                setBookTime("");
                setOverAllDateTime((prev) => ({
                  ...prev,
                  index: i,
                }));
              }}
              style={{ width: `${window.innerWidth / 3}px` }}
            >
              {/* {date_formatter(new Date(el.dateTime), "date mo_short")} */}
              {dateFormatter(new Date(el.date), "WKS, MMS DD")}
            </div>
          ))}
        </div>
        <div className="your_time_slot_data_set">
          {ListOfTimewithDate[BookIndexDate]?.slots?.map((el, i) => {
            let cs = el?.slot_start;

            if (Number(cs[0].slice(0, 2)) >= 12) {
              return "";
            }
          }).length === 0 ? (
            <div style={{ textAlign: "center" }} className="no_time_slot">
              Sorry, no slots are available. Please try a different date.
            </div>
          ) : (
            ListOfTimewithDate[BookIndexDate]?.slots?.map((el, i) => {
              return (
                <div
                  key={i}
                  onClick={() => {
                    setBookTime(el);

                    setOverAllDateTime((prev) => ({
                      ...prev,
                      date: BookDate,
                      time: el,
                    }));
                  }}
                  className={
                    "time_box " +
                    (el?.slot_start === BookTime?.slot_start
                      ? " active_box"
                      : "")
                  }
                  style={{
                    justifyContent: "center",
                  }}
                >
                  {el?.slot_start?.replace(" ", "")}
                  <div className="time_box_tick">
                    {el?.slot_start === BookTime?.slot_start ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM13.707 8.707C13.8892 8.5184 13.99 8.2658 13.9877 8.0036C13.9854 7.7414 13.8802 7.49059 13.6948 7.30518C13.5094 7.11977 13.2586 7.0146 12.9964 7.01233C12.7342 7.01005 12.4816 7.11084 12.293 7.293L9 10.586L7.707 9.293C7.5184 9.11084 7.2658 9.01005 7.0036 9.01233C6.7414 9.0146 6.49059 9.11977 6.30518 9.30518C6.11977 9.49059 6.0146 9.7414 6.01233 10.0036C6.01005 10.2658 6.11084 10.5184 6.293 10.707L8.293 12.707C8.48053 12.8945 8.73484 12.9998 9 12.9998C9.26516 12.9998 9.51947 12.8945 9.707 12.707L13.707 8.707Z"
                          fill="#5F1D1B"
                        />
                      </svg>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default CheckOutTimeSlotV3;
