import React from "react";
import { CommonNavV3 } from "./CommonNavV3";
import { CommonFooterV3 } from "./CommonFooterV3";
import { useNavigate } from "react-router-dom";

export const Confirmed = () => {
  const nav = useNavigate();
  return (
    <>
      {/* <CommonNavV3
        {...{
          nobackbtn: true,
          goback: () => {},
        }}
      /> */}
      <div className="confirmed_section">
        <svg
          width="41"
          height="40"
          viewBox="0 0 41 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.034 6.90999C14.3205 6.80728 15.5418 6.30126 16.524 5.46399C17.6333 4.51918 19.0428 4.00027 20.5 4.00027C21.9571 4.00027 23.3666 4.51918 24.476 5.46399C25.4581 6.30126 26.6794 6.80728 27.966 6.90999C29.4188 7.02611 30.7828 7.65592 31.8134 8.68652C32.844 9.71713 33.4739 11.0811 33.59 12.534C33.692 13.82 34.198 15.042 35.036 16.024C35.9808 17.1333 36.4997 18.5428 36.4997 20C36.4997 21.4571 35.9808 22.8667 35.036 23.976C34.1987 24.9582 33.6927 26.1795 33.59 27.466C33.4739 28.9189 32.844 30.2829 31.8134 31.3135C30.7828 32.3441 29.4188 32.9739 27.966 33.09C26.6794 33.1927 25.4581 33.6987 24.476 34.536C23.3666 35.4808 21.9571 35.9997 20.5 35.9997C19.0428 35.9997 17.6333 35.4808 16.524 34.536C15.5418 33.6987 14.3205 33.1927 13.034 33.09C11.5811 32.9739 10.2171 32.3441 9.18649 31.3135C8.15589 30.2829 7.52608 28.9189 7.40996 27.466C7.30725 26.1795 6.80123 24.9582 5.96396 23.976C5.01915 22.8667 4.50024 21.4571 4.50024 20C4.50024 18.5428 5.01915 17.1333 5.96396 16.024C6.80123 15.0418 7.30725 13.8205 7.40996 12.534C7.52608 11.0811 8.15589 9.71713 9.18649 8.68652C10.2171 7.65592 11.5811 7.02611 13.034 6.90999ZM27.914 17.414C28.2783 17.0368 28.4799 16.5316 28.4753 16.0072C28.4708 15.4828 28.2604 14.9812 27.8896 14.6104C27.5188 14.2395 27.0172 14.0292 26.4928 14.0246C25.9684 14.0201 25.4632 14.2217 25.086 14.586L18.5 21.172L15.914 18.586C15.5368 18.2217 15.0316 18.0201 14.5072 18.0246C13.9828 18.0292 13.4811 18.2395 13.1103 18.6104C12.7395 18.9812 12.5292 19.4828 12.5246 20.0072C12.5201 20.5316 12.7216 21.0368 13.086 21.414L17.086 25.414C17.461 25.7889 17.9696 25.9996 18.5 25.9996C19.0303 25.9996 19.5389 25.7889 19.914 25.414L27.914 17.414Z"
            fill="#5F1D1B"
          />
        </svg>
        <div>Your sample collection is confirmed.</div>
      </div>
      <CommonFooterV3
        {...{
          name: "Go home",
          onclick: () => {
            nav(`/home`);
          },
        }}
      />
    </>
  );
};
