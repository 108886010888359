import React from "react";

export const CommonFooterV3 = ({ name, onclick }) => {
  return (
    <div className="common_footer_v3">
      <div
        className="common_footer_v3_btn"
        onClick={() => {
          onclick();
        }}
      >
        {name}
      </div>
    </div>
  );
};
