import React from "react";

export const RangeShower = ({ list, current }) => {
  return (
    <div className="range_anim_v3">
      {[...Array(list).keys()].map((_, i) => (
        <div className={i + 1 == current && "active"}></div>
      ))}
  
    </div>
  );
};
