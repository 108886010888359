import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CommonNavV3 } from "./CommonNavV3";
import { CommonFooterV3 } from "./CommonFooterV3";
import { RangeShower } from "./RangeShower";
import { MultiSelect } from "../../component/CheckOut/Intake/MultiSelect";
import { MultiSelectV3 } from "./MultiSelectV3";
import { useDispatch, useSelector } from "react-redux";

export const PeriodQuestion = ({ booking_data }) => {
  const nav = useNavigate();
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const [menstral, setmenstral] = useState([
    "Yes, I’m on my period now",
    "No, I’m getting my period later",
    "No, I don’t get a period",
  ]);
  const [pagevalues, setpagevalues] = useState({
    menstralstate: "",
  });

  useEffect(() => {
    if (store?.checktoken_data?.selectedlab?.menstralstate) {
      setpagevalues({
        menstralstate: store?.checktoken_data?.selectedlab?.menstralstate,
      });
    }
  }, [store?.checktoken_data?.selectedlab?.menstralstate]);

  // useEffect(() => {
  //   if (booking_data?.product_id) {
  //     if (booking_data?.product_id?.toLowerCase() == "ft02") {
  //       dispatch({
  //         type: "setCheckToken",
  //         payload: {
  //           ...store.checktoken_data,
  //           selectedlab: {
  //             menstralstate: "Yes, I’m on my period now",
  //           },
  //         },
  //       });
  //       nav(`/bookingslot/period-now/labs`);
  //       return;
  //     }
  //   }
  // }, [booking_data]);
  return (
    <>
      {/* <CommonNavV3
        {...{
          goback: () => {
            nav(-1);
          },
        }}
      /> */}

      <div className="period_question_section">
        <RangeShower
          {...{
            list: 4,
            current: 1,
          }}
        />
        <div className="period_question_title">Are you on your period?</div>
        <div className="period_question_desc">
          We recommend taking the test on Day 2 or 3 of your period.
        </div>
        <MultiSelectV3
          {...{
            list: menstral,
            onMultiClick: (el) => {
              setpagevalues((prev) => ({
                ...prev,
                menstralstate: el(),
              }));
            },
            MultiSelectData: pagevalues?.menstralstate || "",
            multisel: false,
          }}
        />
      </div>

      <CommonFooterV3
        {...{
          name: "Continue",
          onclick: () => {
            if (
              pagevalues.menstralstate ==
              store?.checktoken_data?.selectedlab?.menstralstate
            ) {
              dispatch({
                type: "setCheckToken",
                payload: {
                  ...store.checktoken_data,
                  selectedlab: {
                    ...store.checktoken_data.selectedlab,
                    menstralstate: pagevalues.menstralstate,
                  },
                },
              });
            }
            if (
              pagevalues.menstralstate !=
              store?.checktoken_data?.selectedlab?.menstralstate
            ) {
              dispatch({
                type: "setCheckToken",
                payload: {
                  ...store.checktoken_data,
                  selectedlab: {
                    menstralstate: pagevalues.menstralstate,
                  },
                },
              });
            }
            if (pagevalues.menstralstate == "Yes, I’m on my period now") {
              nav(`/bookingslot/period-now/labs`);
            }
            if (pagevalues.menstralstate == "No, I’m getting my period later") {
              nav(`/bookingslot/period-later/reminder`);
            }
            if (pagevalues.menstralstate == "No, I don’t get a period") {
              nav(`/bookingslot/no-period/labs`);
            }
          },
        }}
      />
    </>
  );
};
