import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CoverSlide } from "./CoverSlide";
import { PeriodQuestion } from "./PeriodQuestion";
import { ChooseLabs } from "./ChooseLabs";
import { ChooseSlots } from "./ChooseSlots";
import { Confirmation } from "./Confirmation";
import { Confirmed } from "./Confirmed";
import { Reminder } from "./Reminder";
import { useDispatch, useSelector } from "react-redux";
import {
  checkPincode,
  checkToken,
  dateFormatter,
  getLabAndSlots,
} from "../../component/API/new_api";
import { MixPanelUsertype } from "../../component/API/automation_api";
import { CommonNavV3 } from "./CommonNavV3";

export const BookSlotV3 = ({ setloading, ProductImages }) => {
  const { periodType = "", pageType = "start" } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [booking_data, setbooking_data] = useState("");
  const [LabData, setLabData] = useState([]);
  const [LabLoading, setLabLoading] = useState(false);
  const [locationData, setlocationData] = useState({});
  // useLayoutEffect(() => {
  //   if (
  //     (periodType == "period-now" ||
  //       periodType == "period-later" ||
  //       periodType == "no-period") &&
  //     !store?.checktoken_data?.selectedlab?.menstralstate
  //   ) {
  //     navigate("/bookingslot/period-question/question");
  //   }
  // }, []);
  const check_user_data = (token) => {
    // setloading("type1");
    // let t_order_id = window.location.search.slice(1);
    let t_order_id = localStorage.getItem("user_data_to_show");
    if (!t_order_id) {
      console.log(1);
      navigate("/home");
      return;
    }
    t_order_id = JSON.parse(t_order_id).id;
    // console.log(t_order_id);
    checkToken(
      token || "",
      (success) => {
        // setloading(false);
        if (success.customer == null) {
          navigate("/404");
        }
        MixPanelUsertype(success);
        dispatch({
          type: "setCheckToken",
          payload: { ...success, token: token },
        });
        if (success?.customer?.intake) {
          let test_booking = success.orderItems.filter(
            (el) => el._id === t_order_id
            // el.product_type == "test" &&
            // el.product_status == "Order Confirmed"
          )[0];
          if (test_booking?._id) {
            if (
              ["order confirmed", "test booked", "period reminder"].includes(
                test_booking.product_status.toLowerCase()
              )
            ) {
              setbooking_data(test_booking);
            } else {
              console.log(2);
              // setloading(false);
              navigate("/home");
            }
          } else {
            console.log(3);
            // setloading(false);
            navigate("/home");
          }
        }
      },
      (err) => {
        // setloading(false);
        setLabLoading(false);
      }
    );
  };
  useEffect(() => {
    if (store?.checktoken_data?.token) {
      // setloading("type1");
      check_user_data(store.checktoken_data.token);
    } else {
      const local_token = localStorage.getItem("user_token_home");
      if (local_token) {
        check_user_data(local_token);
      } else {
        window.open("http://localhost:3000/", "_self");
      }
    }
    return () => {};
  }, [store.checktoken_data.token]);

  useEffect(() => {
    setLabLoading(true);
    setLabData([]);
    if (booking_data?.product_id) {
      let current_date = new Date();
      if (current_date.getHours() >= 3) {
        current_date.setDate(current_date.getDate() + 1);
      }
      let formatted_date = dateFormatter(current_date, "YYYY-MM-DD");
      getLabAndSlots(
        store.checktoken_data.token,
        {
          date: formatted_date,
          pincode: store?.checktoken_data?.customer?.address?.pin_code,
          address: [
            store?.checktoken_data?.customer?.address?.apt,
            store?.checktoken_data?.customer?.address?.address,
            store?.checktoken_data?.customer?.address?.city,
            store?.checktoken_data?.customer?.address?.state,
          ],
        },
        (success) => {
          console.log(success);
          setLabData(success?.labData);
          setlocationData(success?.latlng);
          setLabLoading(false);
        },
        (err) => {
          setLabLoading(false);
        }
      );
    }
  }, [booking_data?.product_id, store?.checktoken_data?.customer?.address]);

  // useEffect(() => {
  //   if (booking_data?.product_id) {
  //     if (
  //       periodType == "period-now" &&
  //       booking_data?.product_id?.toLowerCase() == "ft02"
  //     ) {
  //       dispatch({
  //         type: "setCheckToken",
  //         payload: {
  //           ...store.checktoken_data,
  //           selectedlab: {
  //             menstralstate: "Yes, I’m on my period now",
  //           },
  //         },
  //       });
  //       navigate(`/bookingslot/${periodType}/labs`);
  //       return;
  //     }
  //     if (!store?.checktoken_data?.selectedlab?.menstralstate) {
  //       navigate("/bookingslot/period-question/question");
  //     }
  //   }
  // }, [periodType, booking_data]);
  // console.log(pageType);
  return (
    <>
      <CommonNavV3
        {...{
          nobackbtn: pageType == "confirmed",
          goback: () => {
            if (
              pageType == "question" ||
              (periodType == "period" &&
                pageType == "labs" &&
                booking_data?.product_id?.toLowerCase() == "ft02")
            ) {
              navigate("/home");
            } else {
              navigate(-1);
            }
            // nav(`/bookingslot/period-question/question`);
          },
        }}
      />
      {
        {
          start: <CoverSlide />,
          question: <PeriodQuestion booking_data={booking_data} />,
          labs: (
            <ChooseLabs
              data={LabData}
              booking_data={booking_data}
              LabLoading={LabLoading}
            />
          ),
          slots: <ChooseSlots />,
          reminder: <Reminder />,
          confirmation: (
            <Confirmation
              data={{
                ...ProductImages[booking_data.product_id],
                ...booking_data,
                ...locationData,
              }}
            />
          ),
          confirmed: <Confirmed />,
        }[pageType]
      }
    </>
  );
};
const data = [
  {
    labName: "Thyrocare",
    labPhoto: "https://placehold.co/136x36?text=Thyrocare",
    labStatus: {
      text: "Available",
      isAvailable: true,
    },
    preference: 1,
    slots: [
      {
        date: "Sun, 01 Sep",
        times: [
          {
            id: "slot1",
            startTime: "09:00",
          },
          {
            id: "slot2",
            startTime: "12:00",
          },
          {
            id: "slot3",
            startTime: "13:00",
          },
          {
            id: "slot4",
            startTime: "15:00",
          },
          {
            id: "slot5",
            startTime: "18:00",
          },
        ],
      },
      {
        date: "Mon, 02 Sep",
        times: [
          {
            id: "slot6",
            startTime: "10:00",
          },
          {
            id: "slot7",
            startTime: "11:00",
          },
          {
            id: "slot8",
            startTime: "13:00",
          },
          {
            id: "slot9",
            startTime: "15:00",
          },
          {
            id: "slot10",
            startTime: "16:00",
          },
        ],
      },
      {
        date: "Tue, 03 Sep",
        times: [
          {
            id: "slot11",
            startTime: "10:00",
          },
          {
            id: "slot12",
            startTime: "11:45",
          },
          {
            id: "slot13",
            startTime: "13:00",
          },
          {
            id: "slot14",
            startTime: "16:00",
          },
          {
            id: "slot15",
            startTime: "17:00",
          },
        ],
      },
    ],
  },
  {
    labName: "Tata 1mg",
    labPhoto: "https://placehold.co/136x36?text=Tata+1mg",
    labStatus: {
      text: "Available",
      isAvailable: true,
    },
    preference: 2,
    slots: [
      {
        date: "Mon, 01 Sep",
        times: [
          {
            id: "slot16",
            startTime: "09:00",
          },
          {
            id: "slot17",
            startTime: "10:00",
          },
          {
            id: "slot18",
            startTime: "12:00",
          },
          {
            id: "slot19",
            startTime: "14:00",
          },
          {
            id: "slot20",
            startTime: "16:00",
          },
        ],
      },
      {
        date: "Tue, 02 Sep",
        times: [
          {
            id: "slot21",
            startTime: "10:00",
          },
          {
            id: "slot22",
            startTime: "11:00",
          },
          {
            id: "slot23",
            startTime: "13:00",
          },
          {
            id: "slot24",
            startTime: "15:00",
          },
          {
            id: "slot25",
            startTime: "17:00",
          },
        ],
      },
      {
        date: "Wed, 03 Sep",
        times: [
          {
            id: "slot26",
            startTime: "08:30",
          },
          {
            id: "slot27",
            startTime: "10:30",
          },
          {
            id: "slot28",
            startTime: "12:30",
          },
          {
            id: "slot29",
            startTime: "14:30",
          },
          {
            id: "slot30",
            startTime: "16:30",
          },
        ],
      },
    ],
  },
  {
    labName: "Redcliffe Labs",
    labPhoto: "https://placehold.co/136x36?text=Redcliffe+Labs",
    labStatus: {
      text: "Unavailable",
      isAvailable: false,
    },
    preference: 3,
    slots: [
      {
        date: "Sun, 01 Sep",
        times: [
          {
            id: "slot31",
            startTime: "07:00",
          },
          {
            id: "slot32",
            startTime: "09:00",
          },
          {
            id: "slot33",
            startTime: "11:00",
          },
          {
            id: "slot34",
            startTime: "13:00",
          },
          {
            id: "slot35",
            startTime: "15:00",
          },
        ],
      },
      {
        date: "Mon, 02 Sep",
        times: [
          {
            id: "slot36",
            startTime: "08:00",
          },
          {
            id: "slot37",
            startTime: "10:00",
          },
          {
            id: "slot38",
            startTime: "12:00",
          },
          {
            id: "slot39",
            startTime: "14:00",
          },
          {
            id: "slot40",
            startTime: "16:00",
          },
        ],
      },
      {
        date: "Tue, 03 Sep",
        times: [
          {
            id: "slot41",
            startTime: "09:00",
          },
          {
            id: "slot42",
            startTime: "11:00",
          },
          {
            id: "slot43",
            startTime: "13:00",
          },
          {
            id: "slot44",
            startTime: "15:00",
          },
          {
            id: "slot45",
            startTime: "17:00",
          },
        ],
      },
    ],
  },
  {
    labName: "Neuberg Anand",
    labPhoto: "https://placehold.co/136x36?text=Neuberg+Anand",
    labStatus: {
      text: "Unavailable",
      isAvailable: false,
    },
    preference: 4,
    slots: [
      {
        date: "Sun, 01 Sep",
        times: [
          {
            id: "slot46",
            startTime: "07:30",
          },
          {
            id: "slot47",
            startTime: "09:30",
          },
          {
            id: "slot48",
            startTime: "11:30",
          },
          {
            id: "slot49",
            startTime: "13:30",
          },
          {
            id: "slot50",
            startTime: "15:30",
          },
        ],
      },
      {
        date: "Mon, 02 Sep",
        times: [
          {
            id: "slot51",
            startTime: "08:00",
          },
          {
            id: "slot52",
            startTime: "10:00",
          },
          {
            id: "slot53",
            startTime: "12:00",
          },
          {
            id: "slot54",
            startTime: "14:00",
          },
          {
            id: "slot55",
            startTime: "16:00",
          },
        ],
      },
      {
        date: "Tue, 03 Sep",
        times: [
          {
            id: "slot56",
            startTime: "07:00",
          },
          {
            id: "slot57",
            startTime: "09:00",
          },
          {
            id: "slot58",
            startTime: "11:00",
          },
          {
            id: "slot59",
            startTime: "13:00",
          },
          {
            id: "slot60",
            startTime: "15:00",
          },
        ],
      },
    ],
  },
].sort((a, b) => b.labStatus.isAvailable - a.labStatus.isAvailable);
