import axios from "axios";
import { MixPanelOtpTriggered } from "./automation_api";
const paddNumbers = (num, size) => String(num).padStart(size, "0");
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const monthShortNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const dayShortNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const dateFormatter = (dateInput, format) => {
  const date = dateInput ? new Date(dateInput) : null;
  if (!date || date == "Invalid Date") {
    return "";
  }

  const hours24 = date.getHours();
  const hours12 = hours24 % 12 || 12;
  const ampm = hours24 >= 12 ? "PM" : "AM";
  const dayName = dayNames[date.getDay()];
  const dayShortName = dayShortNames[date.getDay()];

  const options = {
    YY: date.getFullYear().toString().slice(-2),
    YYYY: date.getFullYear(),
    MM: date.getMonth() + 1,
    MMF: monthNames[date.getMonth()],
    MMS: monthShortNames[date.getMonth()],
    DD: date.getDate(),
    D: date.getDay(),
    HH: hours24,
    hh: hours12,
    mm: date.getMinutes(),
    ss: date.getSeconds(),
    A: ampm,
    dddd: dayName,
    ddd: dayShortName,
  };

  return format.replace(
    /YYYY|YY|MMF|MMS|MM|DD|D|HH|hh|mm|ss|dddd|ddd|A/g,
    (match) => paddNumbers(options[match], 2)
  );
};
export const calculateAge = (dob) => {
  const today = new Date();
  const birthDate = new Date(dob);
  // console.log(birthDate);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
};
export const checkToken = (token, ifsuccess, iferror) => {
  let data = "";

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/me",
    headers: {
      authorization: token,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      ifsuccess(response.data);
    })
    .catch((error) => {
      iferror(error);
    });
};
export const updateIntake = (token, intake, ifsuccess, iferror) => {
  let data = JSON.stringify({
    ...intake,
    // token,
  });
  //   return;
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/me/update-intake",
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      ifsuccess(response.data);
    })
    .catch((error) => {
      iferror(error);
    });
};
export const checkPincode = (token, pincode_address, ifsuccess, iferror) => {
  let data = JSON.stringify({
    ...pincode_address,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/test/check-pincode",
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      ifsuccess(response.data);
    })
    .catch((error) => {
      iferror(error);
    });
};
export const getSlots = (token, data1, ifsuccess, iferror) => {
  let data = {
    type: "thyrocare",
    date: "2024-06-15",
    pincode: "400081",
    latitude: 19.158,
    longitude: 72.9598,
    ...data1,
  };
  if (!data1.pincode) {
    return;
  }
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/test/get-slots",
    headers: {
      authorization:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im5pZGhpQGFydmEuaGVhbHRoIiwibmFtZSI6Ik5pZGhpIiwidHlwZSI6IkFkbWluIiwiaXNBZG1pbiI6dHJ1ZSwiaWF0IjoxNzE3NDg5MjYzfQ.xqEZp_55MZ5X4qoX-c0XxShzY6dbz1td0X0d8J8_8_4",
      "Content-Type": "application/json",
    },
    // data: data,
  };
  let date = new Date(data1.date);
  let nextDate = new Date(date).setDate(date.getDate() + 1);
  let next1Date = new Date(date).setDate(date.getDate() + 2);
  Promise.all([
    axios.request({
      ...config,
      data: {
        ...data,
        date: dateFormatter(date, "YYYY-MM-DD"),
      },
    }),
    axios.request({
      ...config,
      data: {
        ...data,
        date: dateFormatter(nextDate, "YYYY-MM-DD"),
      },
    }),
    axios.request({
      ...config,
      data: {
        ...data,
        date: dateFormatter(next1Date, "YYYY-MM-DD"),
      },
    }),
  ])

    .then((response) => {
      ifsuccess(
        response.map((el, i) => ({
          ...[
            {
              dateTime: date.toString(),
              formated_date1: dateFormatter(date, "YYYY-MM-DD"),
            },
            {
              dateTime: new Date(nextDate).toString(),
              formated_date1: dateFormatter(nextDate, "YYYY-MM-DD"),
            },
            {
              dateTime: new Date(next1Date).toString(),
              formated_date1: dateFormatter(next1Date, "YYYY-MM-DD"),
            },
          ][i],

          slots: el.data.map(({ id, slot_start, slot_end }) => ({
            id: id,
            slot: [slot_start, slot_end].join(" - "),
            slotMasterId: id,
          })),
        }))
      );
    })
    .catch((error) => {
      iferror(error);
    });
};

export const updateRemider = (
  token,
  dateTime,
  order_id,
  ifsuccess,
  iferror
) => {
  let data = JSON.stringify({
    reminderDate: dateTime,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/order/test/" + order_id + "/update-reminder",
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      ifsuccess(response.data);
    })
    .catch((error) => {
      iferror(error);
    });
};
export const newBooking = (token, booking_data, ifsuccess, iferror) => {
  let data = JSON.stringify({
    ...booking_data,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/test/new-booking",
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      ifsuccess(response.data);
    })
    .catch((error) => {
      iferror(error);
    });
};
export const addAppoiuntment = (
  token,
  id,
  eventData,
  if_successs,
  if_reject
) => {
  let data = JSON.stringify({
    ...eventData,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/appointments/" + id + "/add-calendly-data",
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
export const updateGoal = (token, goal, if_successs, if_reject) => {
  let data = JSON.stringify({
    goal: goal,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/me/update-goal",
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
export const getGuideBySlug = (token, slug, if_successs, if_reject) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/sanity/guide/" + slug,
    headers: {
      authorization: token,
    },
    data: "",
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
export const UpdateUserAddress = (token, address, if_successs, if_reject) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/me/update-address",
    headers: {
      authorization: token,
    },
    data: address,
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
export const AddClinic = (token, clinicId, if_successs, if_reject) => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  let data = JSON.stringify({
    requestOn: `${year}-${month}-${day}`,
    clinicId,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/me/update-clinic",
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};

export const getGuides = (token, if_successs, if_reject) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/sanity/guides",
    headers: {
      authorization: token,
    },
    data: "",
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
export const ProductImages = {};
export const getProducts = (if_successs, if_reject) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/sanity/services",

    data: "",
  };
  setTimeout(() => {
    axios
      .request(config)
      .then((response) => {
        // console.log(response.data.explore );
        response.data.explore.map((el) => {
          ProductImages[el?.sku || "NA"] = {
            img: el?.image?.asset?._ref
              ? samity_image_convert(el?.image?.asset?._ref)
              : {
                  ft: "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/ARVA/images/default/test.png",
                  hc: "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/ARVA/images/default/coaching.png",
                  dc: "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/ARVA/images/default/consult.png",
                }[el.sku.slice(0, 2).toLowerCase()],
            name: el.shortName || el.name || "",
          };

          return null;
        });

        if_successs(ProductImages);
      })
      .catch((error) => {
        // if_reject(error);
      });
  }, 5000);
};
function samity_image_convert(url) {
  if (url.match("https")) {
    return url;
  }
  let a = url?.split("-");
  return `https://cdn.sanity.io/${a[0]}s/nao51hyy/production/${a
    ?.slice(1, -1)
    ?.join("-")}.${a[a.length - 1]}`;
}
const otp_retry = {
  phone: "",
  retry: false,
};
export const sendOtp = ({ prefix, phone }, if_successs, if_reject) => {
  try {
    let data = JSON.stringify({
      prefix: prefix ? String(prefix) : "91",
      phone: String(phone),
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.arva.health/user/send-otp",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    MixPanelOtpTriggered({
      is_edited: otp_retry.phone !== phone && otp_retry.phone,
      is_retriggered: otp_retry.phone === phone,
    });
    otp_retry.phone = phone;

    axios
      .request(config)
      .then((response) => {
        if_successs({ ...response.data });
      })
      .catch((error) => {
        if_reject(error);
      });
  } catch {
    return if_reject({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const verifyOtp = (
  { prefix, phone, first_name, last_name, email, otp },
  if_successs,
  if_reject
) => {
  try {
    let data = JSON.stringify({
      prefix: prefix ? String(prefix) : "91",
      phone: String(phone),
      first_name,
      last_name,
      email,
      otp,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.arva.health/user/verify-otp",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if_successs({ ...response.data });
      })
      .catch((error) => {
        if_reject(error);
      });
  } catch {
    return if_reject({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const addfc1 = (token, id, eventData, if_successs, if_reject) => {
  let data = JSON.stringify({
    eventUrl: "",
    inviteeUrl: "",
    ...eventData,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/test/" + id + "/add-fc1",
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};
export const getLabAndSlots = (token, data_obj, if_successs, if_reject) => {
  let data = JSON.stringify({
    // "date": "2024-09-14",
    // "pincode": "400081",
    // "address": "A 702, Prathamesh soc, sane guruji nagar, mulund east"
    ...data_obj,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/test/get-slots-v2",
    headers: {
      authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};


export const getAddress = (search, if_successs, if_reject) => {
  let data = JSON.stringify({
    address: search,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/address-autocomplete",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      if_successs(response.data);
    })
    .catch((error) => {
      if_reject(error);
    });
};