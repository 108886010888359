import axios from "axios";
import { utils } from "../utils/utils";
import { utilsArray } from "../../utils/utils";
import { date_formatter } from "../CustInput/CustInput";

const token_key = "arva-token";

export const settoken = (token) => {
  return localStorage.setItem(token_key, token);
};
export const gettoken = () => {
  return localStorage.getItem(token_key);
};
export const format_date = (date) => {
  if (!date) {
    return "";
  }
  if (new Date(date) == "Invalid Date") {
    return "";
  }
  // let temp_Date = new Date(date).toLocaleDateString().split("/");
  let temp_Date = new Date(date);
  temp_Date = [
    temp_Date.getMonth(),
    temp_Date.getDate(),
    temp_Date.getFullYear(),
  ];

  return `${utils.month_arr[temp_Date[0]][0]} ${temp_Date[1]}, ${temp_Date[2]}`;
};

// export const base_url = `https://api.arva.health/api/v1/arva`;.

export let base_url;

if (utilsArray("testing")) {
  base_url = `http://localhost:8080/api/v1/arva`;

  // base_url = `https://api-staging.arva.health/api/v1/arva`;

  // base_url = `https://api.arva.health/api/v1/arva`;

  // base_url = `https://api.arva.health/api/v1/arva`;
  // `http://13.200.53.189:9000/api/v1/arva`;
  // base_url = `https://api-staging.arva.health/api/v1/arva`;
  // base_url = `https://api.arva.health/api/v1/arva`;
} else {
  base_url = `https://api.arva.health/api/v1/arva`;
  // base_url = `https://api-staging.arva.health/api/v1/arva`;
  // base_url = `https://api-arva.test-server.app/api/v1/arva`;
}
// base_url = `https://api.arva.health/api/v1/arva`;

console.error("testing", utilsArray("testing"));

const api = {
  login: "/admin/login",
  check_token: "/admin/check-token",
  get_product: "/admin/get-products",
  add_product: "/admin/add-product",
  edit_product: "/admin/edit-product",
  delete_product: "/admin/delete-product",
  get_discount: "/admin/get-discounts",
  add_discount: "/admin/add-discount",
  edit_discount: "/admin/edit-discount",
  delete_discount: "/admin/delete-discount",
  get_order: "/admin/get-orders",
  dr_add_img: "/admin/add-img-dr",
  add_img: "/admin/add-img",
  add_note: "/admin/add-notes",
  edit_notes: "/admin/edit-notes",
  dr_delete_img: "/admin/delete-img-dr",

  discountcode: "/check-discount",
  chechDefault: "/check-default",
  email_invoice: "/admin/email-invoice",
  send_otp: "/send-otp",
  re_send_otp: "/re-send-otp",
  check_pincode: "/check-pincode",
  check_pincode_admin: "/admin/check-pincode",
  get_appointnment: "/get-apppointment-slots",
  record_intake: "/record-intake",
  check_tokenuser: "/check-token",
  edit_intake: "/edit_intake",
  add_update_goal: "/update-goal",
  edit_notification: "/edit_notification",
  add_order_date: "/add-order-date",
  book_slot_admin: "/admin/book-slot",
  re_book_slot_admin: "/admin/re-book-slot",
  add_reminder_date: "/add-comming-period",
  get_admin_order_list: "/admin/get-order-details",
  edit_admin_order_intake: "/admin/edit-intake",
  edit_orders_biomarkers: "/admin/edit-orders-biomarkers",
  edit_followup: "/admin/edit-followup",
  get_sanity: "/sanity/content",
  get_sanity_product: "/sanity/products",
  get_order_detail_api: "/sanity/blog-data",
  get_guide_detail_api: "/sanity/guide-data",
  get_order_api: "/sanity/explore",

  get_clinics_api: "/sanity/clinics",

  googlelogin: "/google-login",
  googleloginapp: "/google-login-app",
  googlelogin_admin: "/admin/google-login",
  series: "/sanity/series",
  editordersappoint: "/admin/edit-orders-appointment",
  order_reminder: "/admin/update-order-reminder",
  drassign: "/admin/assign-expert",
  cancelorder: "/admin/order-cancel",
  all_users: "/admin/all-users",
  add_users: "/admin/add-users",
  edit_users: "/admin/edit-users",
  edit_address: "/add-order-address",
  checking_payment: "/check-payment",
  hide_goto: "/hide_go_to",
  email_exist: "/email-exists",
  user_clicked: "/user-clicked",
  user_schedule_meeting: "/schedule-meeeting-fc1",
  user_schedule_meeting_doctor: "/schedule-meeeting-doctor",
  user_cancel_meeting: "/cancel-meeeting-fc1",
  coaching_success: "/admin/hhcleads-success",
  coaching_failed: "/admin/hhcleads-failed",

  slotslogshistory: "/log",
};

export const CheckPayment = ({ token }, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.checking_payment}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const HideGoto = (token, callback) => {
  // return
  try {
    axios
      .post(
        `${base_url}${api.hide_goto}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};

export const Series = (callback) => {
  try {
    axios
      .post(`${base_url}${api.series}`, {})
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const Login = ({ email, password }, callback) => {
  try {
    axios
      .post(`${base_url}${api.login}`, { email, password }, {})
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const EmailExist = (email, callback) => {
  try {
    axios
      .post(`${base_url}${api.email_exist}`, { email: email }, {})
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const UserClicked = (data, token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.user_clicked}`,
        { ...data },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const User_Schedule_Meeting = (data, token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.user_schedule_meeting}`,
        { ...data },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const User_Schedule_Meeting_doctor = (data, token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.user_schedule_meeting_doctor}`,
        { ...data },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const User_Cancel_Meeting = (data, token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.user_cancel_meeting}`,
        { ...data },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const DrImgAdd = (data, token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.dr_add_img}`,
        { ...data },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const ImgAdd = (data, token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.add_img}`,
        { ...data },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const AddNotes = (data, token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.add_note}`,
        { ...data },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const EditNotes = (data, token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.edit_notes}`,
        { ...data },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const DrImgDelete = (data, token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.dr_delete_img}`,
        { ...data },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const CommentRating = (callback) => {
  try {
    axios
      .post(`https://api.arva.health/api/v1/arva/sanity/reviews`, {}, {})
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const CheckCode = ({ type, discount }, callback) => {
  // return
  try {
    axios
      .post(`${base_url}${api.discountcode}`, { type, discount }, {})
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch((err) => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const CheckToken = ({ token }, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.check_token}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        // return callback({
        //   status: false,
        //   response: "Network Err or Try Again",
        // });
      });
  } catch {
    // return callback({
    //   status: false,
    //   response: "Network Err or Try Again",
    // });
  }
};
export const GetProduct = (token, callback) => {
  // return;
  try {
    axios
      .post(
        `${base_url}/sanity/servicesv2`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const GetDiscounts = (token, callback) => {
  // return;
  try {
    axios
      .post(
        `${base_url}${api.get_discount}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const GetOrders = (token, callback) => {
  // return;
  try {
    axios
      .post(
        `${base_url}${api.get_order}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch((err1) => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch (err2) {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const AddProduct = (obj, token, callback) => {
  // return;
  try {
    axios
      .post(
        `${base_url}${api.add_product}`,
        { ...obj },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const AddDiscount = (obj, token, callback) => {
  // return;
  try {
    axios
      .post(
        `${base_url}${api.add_discount}`,
        { ...obj },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const EditProduct = (obj, token, callback) => {
  // return;
  try {
    axios
      .post(
        `${base_url}${api.edit_product}`,
        { ...obj },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const EditDiscount = (obj, token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.edit_discount}`,
        { ...obj },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const EditAddress = (obj, token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.edit_address}`,
        { ...obj },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const DeleteProduct = (obj, token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.delete_product}`,
        { ...obj },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const DeleteDicount = (obj, token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.delete_discount}`,
        { ...obj },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};

export const CheckDefault = (email, callback) => {
  axios
    .post(`${base_url}${api.chechDefault}`, {}, {})
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }

      return callback({
        status: true,
        response,
      });
    });
};
export const get_sheet_data = (callback) => {
  axios
    .get(
      `https://script.googleusercontent.com/macros/echo?user_content_key=JjkxySGvZU2MzlPIiZOWPGxwxe-ESH55meR26SPkcn7yYy3fbzuaqfh-g2awtaxOLl_cS2-EBTAM_OjjcxAQGJ_bHqa-YKRXm5_BxDlH2jW0nuo2oDemN9CCS2h10ox_1xSncGQajx_ryfhECjZEnF2XX9cRgd-6H__qP-W2fvKnZ716C8iN4FhmcZI0OfY72xSWg6WRwtLQIGXiI9RSbf_7XKLL5xnhCBZN6AVHgCDDZJeHoCT9_g&lib=MPfoj5r6Bsu5vO3xDLtVKvvpRVCifQbmI`,
      {},
      {}
    )
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }

      return callback({
        status: true,
        response,
      });
    });
};
export const get_blogs_list = (callback) => {
  //   return
  axios
    // .get(
    //   'https://nao51hyy.api.sanity.io/vX/data/query/production?query=*%5B_type+%3D%3D+"article"%5D%7B%0A++_id%2C%0A++title%2C%0A++description%2C%0A++"imageUrl"%3A+coverImage.asset._ref%0A%7D%0A'
    // )
    .post(
      `${base_url}${api.get_order_api}`,
      {},
      {
        // headers: {
        //   Authorization: token,
        // },
      }
      // `https://nao51hyy.api.sanity.io/vX/data/query/production?query=*%5B_type+%3D%3D+"article"+%26%26+_id+%3D%3D+${data._id}%5D%5B0%5D`
    )
    .then((response) => {
      // Handle successful response here
      return callback({
        status: true,
        response,
      });
    })
    .catch((error) => {
      return callback({
        status: true,
        response: {
          query:
            '*[_type == "article"]{\n  _id,\n  title,\n  description,\n  "imageUrl": coverImage.asset._ref\n}\n',
          result: [
            // {
            //   _id: "0c155fe5-6457-4c18-9c5e-981a852e7d33",
            //   title: "What are 6 Signs of High Fertility in Women?",
            //   description:
            //     "Ready to make a baby? (Or not) — Here’s what you should be looking for.",
            //   imageUrl:
            //     "image-c61b24d6409de0f3556e59e87be50c4ba51420ae-564x706-jpg",
            // },
            // {
            //   _id: "8a001478-f2b6-43ce-97f4-5d11140def5d",
            //   title: "When am I most fertile? Ovulation window explained.",
            //   description:
            //     "If your fertility was a puzzle (which it is), your fertile window is the reference picture. Following it can really speed things along! ",
            //   imageUrl:
            //     "image-8c9de174a1fe78ffaa59c0778f65fcab99a5a81d-564x564-jpg",
            // },
          ],
          ms: 4,
        },
      });
    });
  // axios
  //   .get(
  //     ``
  //   )
  //   .then((response) => response.data)
  //   .then((response) => {
  //     if (response.err) {
  //       return callback({
  //         status: false,
  //       });
  //     }

  //     return callback({
  //       status: true,
  //       response,
  //     });
  //   });
};
export const get_clinics_list = (callback) => {
  //   return
  axios
    // .get(
    //   'https://nao51hyy.api.sanity.io/vX/data/query/production?query=*%5B_type+%3D%3D+"article"%5D%7B%0A++_id%2C%0A++title%2C%0A++description%2C%0A++"imageUrl"%3A+coverImage.asset._ref%0A%7D%0A'
    // )
    .post(
      `${base_url}${api.get_clinics_api}`,
      {},
      {
        // headers: {
        //   Authorization: token,
        // },
      }
      // `https://nao51hyy.api.sanity.io/vX/data/query/production?query=*%5B_type+%3D%3D+"article"+%26%26+_id+%3D%3D+${data._id}%5D%5B0%5D`
    )
    .then((response) => {
      // Handle successful response here
      return callback({
        status: true,
        response,
      });
    })
    .catch((error) => {
      return callback({
        status: true,
        response: {
          query:
            '*[_type == "article"]{\n  _id,\n  title,\n  description,\n  "imageUrl": coverImage.asset._ref\n}\n',
          result: [
            {
              _id: "0c155fe5-6457-4c18-9c5e-981a852e7d33",
              title: "What are 6 Signs of High Fertility in Women?",
              description:
                "Ready to make a baby? (Or not) — Here’s what you should be looking for.",
              imageUrl:
                "image-c61b24d6409de0f3556e59e87be50c4ba51420ae-564x706-jpg",
            },
            {
              _id: "8a001478-f2b6-43ce-97f4-5d11140def5d",
              title: "When am I most fertile? Ovulation window explained.",
              description:
                "If your fertility was a puzzle (which it is), your fertile window is the reference picture. Following it can really speed things along! ",
              imageUrl:
                "image-8c9de174a1fe78ffaa59c0778f65fcab99a5a81d-564x564-jpg",
            },
          ],
          ms: 4,
        },
      });
    });
  // axios
  //   .get(
  //     ``
  //   )
  //   .then((response) => response.data)
  //   .then((response) => {
  //     if (response.err) {
  //       return callback({
  //         status: false,
  //       });
  //     }

  //     return callback({
  //       status: true,
  //       response,
  //     });
  //   });
};
export const get_result_list = (callback) => {
  axios
    .get(
      'https://nao51hyy.api.sanity.io/vX/data/query/production?query=*%5B_type+%3D%3D+"report"%5D%7B%0A++_id%2C%0A++name%2C%0A++description%2C%0A++image%2C%0A++"summaryInsights"%3A+summaryInsights%5B%5D->%7B%0A++++_id%2C%0A++++title%2C%0A++++heading%2C%0A++++description%2C%0A++++"relatedArticles"%3A+relatedArticles%5B%5D->%7B%0A++++++_id%2C%0A++++++title%2C%0A++++++slug%2C%0A++++++description%2C%0A++++++image%2C%0A++++++"author"%3A+author->%7B%0A++++++++_id%2C%0A++++++++name+%2F%2F+and+other+author+fields+you+need%0A++++++%7D%0A++++%7D%0A++%7D%2C%0A++"basedOnYourResults"%3A+basedOnYourResults%5B%5D->%7B%0A++++name%2C%0A++++description%2C%0A++++tip%2C%0A++++"relatedBiomarkers"%3A+relatedBiomarkers%5B%5D->%7B%0A++++++name%2C%0A++++++nameShort%2C%0A++++++description%2C%0A++++++"insights"%3A+insights%5B%5D->%7B%0A++++++++score%2C%0A++++++++description%0A++++++%7D%2C%0A++++++"labRange"%3A+labRange%5B%5D->%7B%0A++++++++lab%2C%0A++++++++ageMin%2C%0A++++++++ageMax%2C%0A++++++++lowMin%2C%0A++++++++lowMax%2C%0A++++++++optimalMin%2C%0A++++++++optimalMax%2C%0A++++++++highMin%2C%0A++++++++highMax%0A++++++%7D%0A++++%7D%0A++%7D%0A%7D%0A'
    )
    .then((response) => {
      // Handle successful response here
      return callback({
        status: true,
        response,
      });
    })
    .catch((error) => {
      return callback({
        status: false,
        response: {
          query:
            '*[_type == "report"]{\n  _id,\n  name,\n  description,\n  image,\n  "summaryInsights": summaryInsights[]->{\n    _id,\n    title,\n    heading,\n    description,\n    "relatedArticles": relatedArticles[]->{\n      _id,\n      title,\n      slug,\n      description,\n      image,\n      "author": author->{\n        _id,\n        name // and other author fields you need\n      }\n    }\n  },\n  "basedOnYourResults": basedOnYourResults[]->{\n    name,\n    description,\n    tip,\n    "relatedBiomarkers": relatedBiomarkers[]->{\n      name,\n      nameShort,\n      description,\n      "insights": insights[]->{\n        score,\n        description\n      },\n      "labRange": labRange[]->{\n        lab,\n        ageMin,\n        ageMax,\n        lowMin,\n        lowMax,\n        optimalMin,\n        optimalMax,\n        highMin,\n        highMax\n      }\n    }\n  }\n}\n',
          result: [
            {
              _id: "3a983a7f-efba-4d62-8f48-61cdb28f2a55",
              name: "Fertility (Egg Count)",
              description:
                "Ovarian reserve testing, like this one, gives you an indication of the quantity (how many eggs you have left), not the quality. ",
              image: {
                _type: "image",
                asset: {
                  _ref: "image-c61b24d6409de0f3556e59e87be50c4ba51420ae-564x706-jpg",
                  _type: "reference",
                },
              },
              summaryInsights: null,
              basedOnYourResults: [
                {
                  name: "Egg Count",
                  description:
                    "Think of your ovarian reserve as a basket of eggs. We’re typically born with a basket full of eggs, and every month, one mature egg is formed and released. By the time we reach the end of our reproductive window, also known as menopause, the basket is nearly empty.",
                  tip: "You just found your fertility baseline. It is completely natural for your fertility to decline over time. Your body is doing it’s natural thing! Fertility testing helps us track that decline over time, so we catch major changes at the right time.",
                  relatedBiomarkers: [
                    {
                      name: "Anti-mullerian hormone",
                      nameShort: "AMH",
                      description:
                        "AMH levels correlate with the number of potential eggs. While it is not a crystal ball into your fertility future, it gives you more information about your reproductive health. Your AMH level will NOT tell you whether or not you’re fertile. It only gives you an idea of the quantity of your eggs, not the quality.",
                      insights: [
                        {
                          score: "Low",
                          description:
                            "​​Low AMH levels typically indicate a smaller ovarian reserve – lower number of follicles in the ovaries.",
                        },
                        {
                          score: "Optimal",
                          description:
                            "Your AMH may indicate that your ovaries have the average number of ovarian follicles.",
                        },
                        {
                          score: "High",
                          description:
                            "Higher AMH levels typically indicate a larger ovarian reserve – higher number of follicles in the ovaries.",
                        },
                      ],
                      labRange: [
                        {
                          highMax: 20,
                          lab: "Thyrocare Technologies",
                          optimalMin: 2,
                          lowMin: 0,
                          lowMax: 1.9,
                          optimalMax: 4,
                          highMin: 4.1,
                          ageMin: 25,
                          ageMax: 35,
                        },
                        {
                          lowMin: 0,
                          lowMax: 1.79,
                          highMax: 20,
                          optimalMax: 2.8,
                          highMin: 2.81,
                          lab: "Thyrocare Technologies",
                          ageMin: 36,
                          ageMax: 100,
                          optimalMin: 1.8,
                        },
                      ],
                    },
                  ],
                },
                {
                  name: "Ovulation",
                  description:
                    "It’s the process your body goes through when releasing an egg. Your ability to ovulate is influenced by things like your BMI, birth control, and certain health conditions like PCOS. A key hormone, Prolactin, can help you understand if there’s anything interfering with ovulation. ",
                  tip: null,
                  relatedBiomarkers: [
                    {
                      insights: [
                        {
                          score: "Low",
                          description:
                            "Prolactin interacts with various hormones, and low levels might contribute to irregular periods or disrupted hormonal balance. ",
                        },
                        {
                          score: "Optimal",
                          description:
                            "Normal PRL levels support the release of eggs from the ovaries (ovulation), which is essential for achieving pregnancy.",
                        },
                        {
                          score: "High",
                          description:
                            "Consistently high prolactin levels can interfere with the production FSH and LH, affecting ovulation, causing irregular or absent periods – making it harder to conceive.",
                        },
                      ],
                      labRange: [
                        {
                          highMin: 29.1,
                          lab: "Thyrocare Technologies",
                          ageMin: 0,
                          lowMin: 0,
                          lowMax: 2.9,
                          optimalMin: 3,
                          optimalMax: 29,
                          ageMax: 100,
                          highMax: 220,
                        },
                      ],
                      name: "Prolactin",
                      nameShort: "PRL",
                      description:
                        "PRL is responsible for stimulating milk production & pausing ovulation after you give birth. Testing PRL helps us learn about any irregularities in your cycle.\n\nProlactin levels can fluctuate throughout the day and can also be influenced by factors such as stress, exercise, sexual activity, and medications. ",
                    },
                    {
                      name: "Follicle stimulating hormone",
                      nameShort: "FSH",
                      description:
                        "FSH, a signal from your brain, helps the chosen set of eggs in your ovaries mature.",
                      insights: [
                        {
                          score: "Low",
                          description:
                            "Consistently low levels of FSH can indicate a problem with ovulation. Without enough FSH, your ovaries might not release an egg preventing pregnancy.",
                        },
                        {
                          score: "Optimal",
                          description:
                            "Average FSH levels suggest that your pituitary gland is making enough FSH to kickstart your ovaries. ",
                        },
                        {
                          score: "High",
                          description:
                            'A high FSH in isolation is not very concerning if your AMH level is "normal." We recommend getting tested again in 3 months. ',
                        },
                      ],
                      labRange: [
                        {
                          ageMin: 0,
                          ageMax: 100,
                          lowMin: 0,
                          highMin: 10.1,
                          lab: "Thyrocare Technologies",
                          lowMax: 2.49,
                          optimalMin: 2.5,
                          optimalMax: 10,
                          highMax: 100,
                        },
                      ],
                    },
                    {
                      nameShort: "E2",
                      description:
                        "E2 is a good indication of how well your ovaries and menstrual cycle are working, and can be a signpost of menopause and POI.",
                      insights: [
                        {
                          score: "Low",
                          description:
                            "Consistently low E2 can reflect poor follicle development and egg quality––it can also affect your uterine lining which needs to be comfy enough for an egg to implant.",
                        },
                        {
                          score: "Optimal",
                          description:
                            "Optimal E2 hormone levels support healthy reproductive function.",
                        },
                        {
                          score: "High",
                          description:
                            "High E2 alone doesn't mean it will take longer to conceive, but if E2 is consistently high on the third day of your period then it could be the contributing factor for irregular periods, and affect implantation of the fertilised embryo.",
                        },
                      ],
                      labRange: [
                        {
                          lowMax: 19.9,
                          optimalMax: 50,
                          highMin: 50.1,
                          highMax: 350,
                          lab: "Thyrocare Technologies",
                          ageMin: 0,
                          ageMax: 100,
                          lowMin: 0,
                          optimalMin: 20,
                        },
                      ],
                      name: "Estradiol",
                    },
                  ],
                },
                {
                  name: "BMI",
                  description:
                    "Poor egg quality can lead to difficulties in achieving a successful pregnancy and an increased risk of miscarriage. \n",
                  tip: null,
                  relatedBiomarkers: [
                    {
                      name: "Body Mass Index",
                      nameShort: "BMI",
                      description:
                        "BMI impacts hormonal balance, ovulation, and therefore can impact the quality of eggs produced. ",
                      insights: [
                        {
                          score: "Low",
                          description:
                            "Low body fat levels can lead to irregular or absent menstrual cycles, making it more challenging to conceive.",
                        },
                        {
                          score: "Optimal",
                          description:
                            "Having a normal BMI is generally associated with optimal fertility. ",
                        },
                        {
                          score: "High",
                          description:
                            "High body fat levels can affect fertility by causing hormonal imbalances and insulin resistance. It might lead to irregular ovulation and make it harder to conceive.",
                        },
                      ],
                      labRange: null,
                    },
                  ],
                },
              ],
            },
            {
              image: {
                _type: "image",
                asset: {
                  _ref: "image-8c9de174a1fe78ffaa59c0778f65fcab99a5a81d-564x564-jpg",
                  _type: "reference",
                },
              },
              summaryInsights: null,
              basedOnYourResults: [
                {
                  name: "Insulin Resistance",
                  description:
                    "Weight fluctuations, stubborn abdomen fat, sugar cravings aren’t your fault. You and 70% of PCOS girlies worldwide can easily blame Insulin Resistant PCOS.",
                  tip: null,
                  relatedBiomarkers: null,
                },
                {
                  name: "Androgen Excess",
                  description:
                    "High levels of male sex hormones can indicate signs of PCOS. Common symptoms of excess androgen are excess facial hair (hirsutism), acne, and weight fluctuations.",
                  tip: "Diagnosing PCOS is not all cut and dry as you might expect. Your testosterone levels might come back normal, but you still have symptoms of androgen excess. ",
                  relatedBiomarkers: [
                    {
                      name: "Free Testosterone",
                      nameShort: "Free T",
                      description:
                        "Free T is classified as an Androgen—a primary male sex hormone. ",
                      insights: [
                        {
                          score: "Optimal",
                          description:
                            "Your body is producing a normal amount of male sex hormones.",
                        },
                        {
                          score: "High",
                          description:
                            "Elevated levels of androgens disrupt the hormone balance causing anovulation, insulin resistance, weight fluctuations and so much more. ",
                        },
                      ],
                      labRange: [
                        {
                          ageMax: 100,
                          lowMax: null,
                          optimalMax: 2.85,
                          highMin: 2.86,
                          highMax: 10,
                          lab: "Thyrocare Technologies",
                          ageMin: 0,
                          lowMin: null,
                          optimalMin: 0,
                        },
                      ],
                    },
                    {
                      name: "DHEA—Sulphate",
                      nameShort: "DHEAS",
                      description:
                        "DHEAS behaves as an indicator of your stress levels. DHEAS is produced in the adrenal gland, which regulates your response to stress, sleep and immunity. ",
                      insights: [
                        {
                          score: "Low",
                          description:
                            "Low DHEAS is not a contributing factor of PCOS but might suggest a different underlying cause or hormonal imbalance.",
                        },
                        {
                          score: "Optimal",
                          description:
                            "Your body is producing a normal amount of male sex hormones and not likely a contributing factor in androgen imbalance.",
                        },
                        {
                          score: "High",
                          description:
                            "If your DHEAS is consistently high, this could be due to poor stress management, circadian rhythm (sleep), caffeine intake or poor immunity.",
                        },
                      ],
                      labRange: [
                        {
                          highMax: 500,
                          ageMax: 25,
                          lowMin: 0,
                          lowMax: 109.9,
                          optimalMin: 110,
                          optimalMax: 250,
                          highMin: 250.1,
                          lab: "Thyrocare Technologies",
                          ageMin: 0,
                        },
                        {
                          ageMax: 35,
                          lowMin: 0,
                          optimalMin: 85,
                          highMin: 250.1,
                          highMax: 500,
                          lab: "Thyrocare Technologies",
                          ageMin: 26,
                          lowMax: 84.9,
                          optimalMax: 250,
                        },
                        {
                          lab: "Thyrocare Technologies",
                          ageMin: 36,
                          lowMin: 0,
                          optimalMax: 250,
                          highMax: 500,
                          ageMax: 45,
                          lowMax: 56.9,
                          optimalMin: 57,
                          highMin: 250.1,
                        },
                      ],
                    },
                  ],
                },
                {
                  name: "AMH Factor",
                  description:
                    "Higher AMH = Higher Ovarian Follicles\n\nHowever, some of these might be immature ovarian follicles, which leads to irregular (or no) ovulation. Without ovulation, natural pregnancy is more difficult and can increase the time to conceive kids.",
                  tip: null,
                  relatedBiomarkers: [
                    {
                      insights: [
                        {
                          score: "Low",
                          description:
                            "​​Low AMH levels typically indicate a smaller ovarian reserve – lower number of follicles in the ovaries.",
                        },
                        {
                          score: "Optimal",
                          description:
                            "Your AMH may indicate that your ovaries have the average number of ovarian follicles.",
                        },
                        {
                          score: "High",
                          description:
                            "Higher AMH levels typically indicate a larger ovarian reserve – higher number of follicles in the ovaries.",
                        },
                      ],
                      labRange: [
                        {
                          lowMax: 1.9,
                          optimalMax: 4,
                          lab: "Thyrocare Technologies",
                          ageMin: 25,
                          ageMax: 35,
                          lowMin: 0,
                          optimalMin: 2,
                          highMin: 4.1,
                          highMax: 20,
                        },
                        {
                          lowMin: 0,
                          highMax: 20,
                          lab: "Thyrocare Technologies",
                          ageMin: 36,
                          ageMax: 100,
                          lowMax: 1.79,
                          optimalMin: 1.8,
                          optimalMax: 2.8,
                          highMin: 2.81,
                        },
                      ],
                      name: "Anti-mullerian hormone",
                      nameShort: "AMH",
                      description:
                        "AMH levels correlate with the number of potential eggs. While it is not a crystal ball into your fertility future, it gives you more information about your reproductive health. Your AMH level will NOT tell you whether or not you’re fertile. It only gives you an idea of the quantity of your eggs, not the quality.",
                    },
                  ],
                },
              ],
              _id: "d4019fa3-e548-47fb-a59a-74441dce5a12",
              name: "Signs of PCOS",
              description:
                "PCOS is a hormone imbalance that often results in irregular periods or lack of ovulation, which in turn, affects your fertility.",
            },
            {
              _id: "fe54d76c-1016-4ada-aedb-1f93938c2853",
              name: "Thyroid Health",
              description:
                "Thyroid hormones control the speed at which your body works, also known as your metabolism, and plays a crucial role in menstrual cycles, ovulation, and conception.",
              image: {
                _type: "image",
                asset: {
                  _type: "reference",
                  _ref: "image-f6e639ccbb4f6f35d0542d398df77e4370bbfcce-750x500-webp",
                },
              },
              summaryInsights: null,
              basedOnYourResults: [
                {
                  name: "Thyroid x Fertility",
                  description:
                    "Both hyperthyroidism and hypothyroidism have been linked to abnormal menstrual cycles.",
                  tip: null,
                  relatedBiomarkers: [
                    {
                      nameShort: "TSH",
                      description:
                        'TSH is a "messenger hormone" that tells the thyroid gland to produce hormones.',
                      insights: [
                        {
                          score: "Low",
                          description:
                            "Your TSH levels indicate an overactive thyroid gland also known as “hyperthyroidism”.",
                        },
                        {
                          score: "Optimal",
                          description:
                            "Your thyroid is likely functioning properly and not an inhibiting factor in fertility.",
                        },
                        {
                          score: "High",
                          description:
                            "Your TSH levels could indicate an underactive thyroid gland also known as “hypothyroidism”.",
                        },
                      ],
                      labRange: [
                        {
                          lowMax: 2.4,
                          lab: "Thyrocare Technologies",
                          ageMin: 0,
                          optimalMin: 2.5,
                          optimalMax: 5,
                          highMin: 5.1,
                          highMax: 10,
                          ageMax: 100,
                          lowMin: 0,
                        },
                      ],
                      name: "Thyroid stimulating hormone",
                    },
                    {
                      labRange: [
                        {
                          optimalMin: 4.87,
                          highMax: 20,
                          lowMin: 0,
                          lowMax: 4.86,
                          optimalMax: 11.72,
                          highMin: 11.73,
                          lab: "Thyrocare Technologies",
                          ageMin: 0,
                          ageMax: 100,
                        },
                      ],
                      name: "Thyroxine",
                      nameShort: "T4",
                      description:
                        "T4 plays a vital role in regulating the body's metabolic rate, heart, and digestive functions, muscle control, brain development, and maintenance of bones.",
                      insights: [
                        {
                          score: "Low",
                          description:
                            "When pituitary gland senses T4 is low, it produces a lot of TSH to try to get the thyroid to kick thyroid production into gear.",
                        },
                        {
                          score: "Optimal",
                          description:
                            "Your thyroid gland is helping regulate the pace of your body’s energy usage as expected.",
                        },
                        {
                          score: "High",
                          description:
                            "When the pituitary gland senses T4 is high, it slows down the production of TSH to try to control the thyroid production.",
                        },
                      ],
                    },
                    {
                      name: "Triiodothyronine",
                      nameShort: "T3",
                      description:
                        "T3, similar to T4 plays a significant role in the heart and digestive functions, muscle control, brain development and function, and the maintenance of bones.",
                      insights: [
                        {
                          score: "Low",
                          description:
                            "A low T3 test result can indicate an under-active thyroid, or hypothyroidism.",
                        },
                        {
                          score: "Optimal",
                          description:
                            "Your thyroid gland is helping regulate the pace of your body’s energy usage as expected.",
                        },
                        {
                          score: "High",
                          description:
                            "A high T3 test result can indicate an underactive thyroid, or hyperthyroidism.",
                        },
                      ],
                      labRange: [
                        {
                          highMax: 250,
                          lab: "Thyrocare Technologies",
                          lowMin: 0,
                          highMin: 159.1,
                          optimalMin: 58,
                          optimalMax: 159,
                          ageMin: 0,
                          ageMax: 100,
                          lowMax: 57.9,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
          ms: 136,
        },
      });
    });
  // axios
  //   .get(
  //     ``
  //   )
  //   .then((response) => response.data)
  //   .then((response) => {
  //     if (response.err) {
  //       return callback({
  //         status: false,
  //       });
  //     }

  //     return callback({
  //       status: true,
  //       response,
  //     });
  //   });
};

export const get_blogs_data = (data, callback) => {
  // return
  axios
    .post(
      `${base_url}${api.get_order_detail_api}`,
      { slug: data.slug }
      // {
      //   headers: {
      //     Authorization: token,
      //   },
      // }
      // `https://nao51hyy.api.sanity.io/vX/data/query/production?query=*%5B_type+%3D%3D+"article"+%26%26+_id+%3D%3D+${data._id}%5D%5B0%5D`
    )
    .then((response) => {
      // Handle successful response here
      return callback({
        status: true,
        response,
      });
    })
    .catch((error) => {
      return callback({
        status: true,

        response: {
          query:
            '*[_type == "article" && _id == "0c155fe5-6457-4c18-9c5e-981a852e7d33"][0]',
          result: {
            _rev: "Q2FCkGHH1q37qOS0Eq01WB",
            _type: "article",
            title: "What are 6 Signs of High Fertility in Women?",
            content: [
              {
                children: [
                  {
                    _type: "span",
                    marks: [],
                    text: "We talk a lot about infertility — what it is, when you should start thinking about it, the challenges it can bring, and the options you might have after. But a big part of family planning is also recognising the signs of high fertility, AKA figuring out if your body is a baby-making ",
                    _key: "fe3f5fb4ab630",
                  },
                  {
                    _key: "fe3f5fb4ab631",
                    _type: "span",
                    marks: ["em"],
                    text: "machine",
                  },
                  {
                    _type: "span",
                    marks: [],
                    text: " 👼🏼",
                    _key: "fe3f5fb4ab632",
                  },
                ],
                _type: "block",
                style: "normal",
                _key: "940dc618b0a3",
                markDefs: [],
              },
              {
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: [],
                    text: "For those looking to conceive, knowing these signs can help you understand how to stay in sync with your body. And if you’re not in the market for a baby right now, these symptoms can be an indicator that you should consult a doctor and update your birth control options.\n",
                    _key: "3bd050823ceb0",
                  },
                ],
                _type: "block",
                style: "normal",
                _key: "49c27300f2fb",
              },
              {
                children: [
                  {
                    _type: "span",
                    marks: [],
                    text: "Ready? Ok let’s go:",
                    _key: "24555d3ed50e0",
                  },
                ],
                _type: "block",
                style: "normal",
                _key: "5dec9b6bdb87",
                markDefs: [],
              },
              {
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: ["strong"],
                    text: "High Antral Follicle Count: ",
                    _key: "3d24cead4ffc0",
                  },
                  {
                    _key: "3d24cead4ffc1",
                    _type: "span",
                    marks: [],
                    text: "Ok ignore the big words. Your Antral Follicle Count is essentially the number of eggs you have reserved for baby-making. During a transvaginal ultrasound, your doctor can actually see the number of follicles in your ovaries.More follicles = high ovarian reserve = good stash of eggs ready for fertilisation!",
                  },
                ],
                level: 1,
                _type: "block",
                style: "normal",
                _key: "a3861963d388",
                listItem: "number",
              },
              {
                style: "normal",
                _key: "f8696740e5a9",
                listItem: "number",
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: ["strong"],
                    text: "Regular periods: ",
                    _key: "19faf524a08b0",
                  },
                  {
                    text: "If we haven’t said it enough, tracking your period is essential. Especially when you’re trying to conceive. Regular periods are basically like a built-in ovulation calendar, because you can easily pinpoint when you’re most likely to ovulate, invite a “friend” over 😉, and get busy!",
                    _key: "19faf524a08b1",
                    _type: "span",
                    marks: [],
                  },
                ],
                level: 1,
                _type: "block",
              },
              {
                children: [
                  {
                    text: "Changes in discharge: ",
                    _key: "748ff04f746e0",
                    _type: "span",
                    marks: ["strong"],
                  },
                  {
                    _type: "span",
                    marks: [],
                    text: "Ok hear us out. At different stages of your cycle, your discharge (AKA cervical mucus) probably looks different. Watch closely, because when it starts resembling the consistency and colour of egg whites that’s peak fertility, baby! The mucus helps sperm find their way to the egg, and lube your cervix up for some enjoyable sex.",
                    _key: "748ff04f746e1",
                  },
                ],
                level: 1,
                _type: "block",
                style: "normal",
                _key: "dea7df4315ec",
                listItem: "number",
                markDefs: [],
              },
              {
                _type: "block",
                style: "normal",
                _key: "3762cb6eb92d",
                listItem: "number",
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: ["strong"],
                    text: "Healthy levels of Anti-Mullerian Hormone (AMH): ",
                    _key: "26c806364fb40",
                  },
                  {
                    marks: [],
                    text: "Ok you might know the number, but do you know the health? AMH tests give us insights into your egg count and the health of your ovarian reserve. When your AMH levels are healthy, it means your ovaries are in good shape for fertility. It's like having the inside scoop on your egg reserve!",
                    _key: "e092f5a048de0",
                    _type: "span",
                  },
                ],
                level: 1,
              },
              {
                children: [
                  {
                    _key: "ee39636e4c390",
                    _type: "span",
                    marks: ["strong"],
                    text: "(Relatively) chill period symptoms: ",
                  },
                  {
                    _type: "span",
                    marks: [],
                    text: "Are you the kind of person who just pops a painkiller while your friends are dying from cramps? Well that’s a sign that your reproductive system is in a pretty healthy state. It's like your body's way of saying, \"Hey, keep doing what you’re doing!\"",
                    _key: "ee39636e4c391",
                  },
                ],
                level: 1,
                _type: "block",
                style: "normal",
                _key: "d0d5a05fd94c",
                listItem: "number",
                markDefs: [],
              },
              {
                _type: "block",
                style: "normal",
                _key: "5e464ba55cbb",
                listItem: "number",
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: ["strong"],
                    text: "Consistent Ovulation Tests: ",
                    _key: "421e4b74ce130",
                  },
                  {
                    _type: "span",
                    marks: [],
                    text: "Ok this might see obvious, but when you’re trying to conceive and taking monthly ovulation tests a positive result is… positive! Consistent results means your body is regularly ovulating healthily. \n",
                    _key: "421e4b74ce131",
                  },
                ],
                level: 1,
              },
              {
                _type: "block",
                style: "normal",
                _key: "30e62989d510",
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: [],
                    text: "Checked off all these boxes? Great! You’re ready to make a baby 😛",
                    _key: "b40ee2689bd30",
                  },
                ],
              },
              {
                _type: "block",
                style: "normal",
                _key: "b1eb446fcde1",
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: [],
                    text: "But remember that high fertility doesn’t guarantee success right away. Speaking to a healthcare professional can give you personalised guidance and even emotional support through your journey. We’re always with you, so don’t hesitate to reach out if you just need to talk.",
                    _key: "e0ed70dff70c0",
                  },
                ],
              },
              {
                style: "normal",
                _key: "b59448e2f1c8",
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: [],
                    text: "You’ve got this 💪🏼",
                    _key: "3d92ea4f2c510",
                  },
                ],
                _type: "block",
              },
            ],
            _updatedAt: "2023-09-30T12:07:43Z",
            _createdAt: "2023-09-28T13:11:08Z",
            coverImage: {
              hotspot: {
                height: 1,
                _type: "sanity.imageHotspot",
                width: 1,
                x: 0.5,
                y: 0.5,
              },
              _type: "image",
              asset: {
                _ref: "image-c61b24d6409de0f3556e59e87be50c4ba51420ae-564x706-jpg",
                _type: "reference",
              },
              crop: {
                right: 0,
                top: 0,
                left: 0,
                bottom: 0,
                _type: "sanity.imageCrop",
              },
            },
            subscriptionTier: "Free",
            slug: {
              current: "what-are-6-signs-of-high-fertility-in-women",
              _type: "slug",
            },
            date: "2023-06-26",
            author: {
              _ref: "a97a52b1-a15c-43aa-924c-5324ff1f79fe",
              _type: "reference",
            },
            description:
              "Ready to make a baby? (Or not) — Here’s what you should be looking for.",
            relatedArticles: [
              {
                _ref: "8a001478-f2b6-43ce-97f4-5d11140def5d",
                _type: "reference",
                _key: "07cb268e6ff3",
              },
            ],
            topic: [
              {
                _ref: "e1c60af8-e533-4106-b967-9d3e01c93d27",
                _type: "reference",
                _key: "631d50d821ba",
              },
            ],
            _id: "0c155fe5-6457-4c18-9c5e-981a852e7d33",
          },
          ms: 5,
        },
        response: {
          query:
            '*[_type == "article" && _id == "8a001478-f2b6-43ce-97f4-5d11140def5d"][0]',
          result: {
            coverImage: {
              _type: "image",
              asset: {
                _ref: "image-8c9de174a1fe78ffaa59c0778f65fcab99a5a81d-564x564-jpg",
                _type: "reference",
              },
            },
            description:
              "If your fertility was a puzzle (which it is), your fertile window is the reference picture. Following it can really speed things along! ",
            title: "When am I most fertile? Ovulation window explained.",
            _updatedAt: "2023-10-02T18:14:38Z",
            content: [
              {
                style: "normal",
                _key: "c24e7c98b5e0",
                markDefs: [],
                children: [
                  {
                    text: "If you’re anything like us, conception always seemed so simple. Sure, there’s stories in every show about infertility and adoption… But somehow we think if we just “do the deed” when we’re ready, it can magically happen for us.\n",
                    _key: "9b55c79f74570",
                    _type: "span",
                    marks: [],
                  },
                ],
                _type: "block",
              },
              {
                caption: "TEST",
                _key: "f0859a43e877",
                asset: {
                  _type: "reference",
                  _ref: "image-5a59ed039a49e66890ac034ca1b26a59229d6fa7-1040x333-png",
                },
                _type: "image",
                alt: "TEST",
              },
              {
                children: [
                  {
                    marks: [],
                    text: "Well, after months of reading and research, we’ve come to the conclusion that it ",
                    _key: "c53ed0ae612e0",
                    _type: "span",
                  },
                  {
                    _type: "span",
                    marks: ["em"],
                    text: "IS",
                    _key: "c53ed0ae612e1",
                  },
                  {
                    _type: "span",
                    marks: [],
                    text: " more difficult that was explained to us. ",
                    _key: "c53ed0ae612e2",
                  },
                  {
                    _type: "span",
                    marks: ["em"],
                    text: "So",
                    _key: "c53ed0ae612e3",
                  },
                  {
                    _type: "span",
                    marks: [],
                    text: " difficult, in fact, that we’ve built a whole company around demystifying it. We’re taking baby-making matters into our own hands, by talking about how you can optimise fertility.\n",
                    _key: "c53ed0ae612e4",
                  },
                ],
                _type: "block",
                style: "normal",
                _key: "636708e4b056",
                markDefs: [],
              },
              {
                children: [
                  {
                    _type: "span",
                    marks: ["strong"],
                    text: "What’s the Fertile Window?",
                    _key: "64955b5dd5c40",
                  },
                ],
                _type: "block",
                style: "normal",
                _key: "e41ce676660d",
                markDefs: [],
              },
              {
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: [],
                    text: "Your fertile window describes the period in the middle of your menstrual cycle when conception is most likely. For the average person, the fertile window lasts about 6 days — 1 day of ovulation (when the egg gets released from the ovary) and 5 days before that.",
                    _key: "d85de84709730",
                  },
                ],
                _type: "block",
                style: "normal",
                _key: "4016ff5669c7",
              },
              {
                style: "normal",
                _key: "88b2428b46e8",
                markDefs: [],
                children: [
                  {
                    _key: "11f391cf60b30",
                    _type: "span",
                    marks: [],
                    text: "Yep, ",
                  },
                  {
                    _key: "11f391cf60b31",
                    _type: "span",
                    marks: ["em"],
                    text: "only",
                  },
                  {
                    marks: [],
                    text: " 6 days. With all the fanfare and theatrics around our periods, ovulation should be an even bigger event, don’t you think? 🙄\n",
                    _key: "11f391cf60b32",
                    _type: "span",
                  },
                ],
                _type: "block",
              },
              {
                markDefs: [
                  {
                    href: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC188498/",
                    _key: "1a3e328c1d5f",
                    _type: "link",
                  },
                ],
                children: [
                  {
                    _type: "span",
                    marks: [],
                    text: "Having sex during your fertile window doesn’t ",
                    _key: "35fa6fc3066b0",
                  },
                  {
                    marks: ["em"],
                    text: "always",
                    _key: "35fa6fc3066b1",
                    _type: "span",
                  },
                  {
                    text: " mean you’ll get pregnant, it’s just the best time to try. Even if you get all the calculations and timing right, there’s only a ",
                    _key: "35fa6fc3066b2",
                    _type: "span",
                    marks: [],
                  },
                  {
                    _type: "span",
                    marks: ["1a3e328c1d5f"],
                    text: "25-30% chance of conceiving",
                    _key: "35fa6fc3066b3",
                  },
                  {
                    _type: "span",
                    marks: [],
                    text: " every month, and that tends to decrease as we get older.\n",
                    _key: "35fa6fc3066b4",
                  },
                ],
                _type: "block",
                style: "normal",
                _key: "bd8528cc8dcc",
              },
              {
                _type: "block",
                style: "normal",
                _key: "19d92a30cf6c",
                markDefs: [],
                children: [
                  {
                    text: "That’s because while sperm can survive in the uterus for up to 5 days, eggs must be fertilised 12-24 hours after release to remain viable. After that the window closes until your next cycle.",
                    _key: "f8ae7faa1d1e0",
                    _type: "span",
                    marks: [],
                  },
                ],
              },
              {
                markDefs: [],
                children: [
                  {
                    _key: "6ba8a586d5d50",
                    _type: "span",
                    marks: [],
                    text: "\n",
                  },
                ],
                _type: "block",
                style: "normal",
                _key: "a34f4914cf4e",
              },
              {
                style: "normal",
                _key: "1876fd369163",
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: ["strong"],
                    text: "When does Ovulation happen?",
                    _key: "1dca1c46eb320",
                  },
                ],
                _type: "block",
              },
              {
                _type: "block",
                style: "normal",
                _key: "05a13227517e",
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: [],
                    text: "Even if you have a 28-day cycle, ovulation doesn’t always fall midway on Day 14. It can vary from month to month depending on a bunch of hormonal factors. If your periods are irregular, your ovulation might occur over a wider range of days.",
                    _key: "1ff69d3f80630",
                  },
                ],
              },
              {
                markDefs: [],
                children: [
                  {
                    text: "That’s why it’s important to track your ovulation accurately. There are 5 ways you can track your fertility, and we’ve explained the A-Z of them right here.",
                    _key: "5417f84f94790",
                    _type: "span",
                    marks: [],
                  },
                ],
                _type: "block",
                style: "normal",
                _key: "3aa4ef039352",
              },
              {
                _type: "block",
                style: "normal",
                _key: "a349cc06cb2a",
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: [],
                    text: "\n",
                    _key: "dad940a8dfa40",
                  },
                ],
              },
              {
                _key: "f5cc9e82e00b",
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: ["strong"],
                    text: "Can I get pregnant any other time?",
                    _key: "a5670a7c4f3e0",
                  },
                ],
                _type: "block",
                style: "normal",
              },
              {
                markDefs: [
                  {
                    href: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8740731/",
                    _key: "2e8661ff4da1",
                    _type: "link",
                  },
                ],
                children: [
                  {
                    marks: [],
                    text: "Yes, including during your period. If you have an irregular or lengthier period, it indicates that ovulation is irregular too. Some months the egg could be released early, late or not at all. So it’s very possible to conceive even while menstruating. Studies show that women with irregular periods can ",
                    _key: "28a350b721670",
                    _type: "span",
                  },
                  {
                    text: "conceive anytime between 12-60 days",
                    _key: "50d026aa8fee1",
                    _type: "span",
                    marks: ["2e8661ff4da1"],
                  },
                  {
                    _type: "span",
                    marks: [],
                    text: " into their cycle.",
                    _key: "50d026aa8fee2",
                  },
                ],
                _type: "block",
                style: "normal",
                _key: "26ad48a91c55",
              },
              {
                _key: "32d7299b9b08",
                markDefs: [],
                children: [
                  {
                    marks: [],
                    text: "\n",
                    _key: "f1fc63a528f40",
                    _type: "span",
                  },
                ],
                _type: "block",
                style: "normal",
              },
              {
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: ["strong"],
                    text: "When are my best chances to get pregnant?",
                    _key: "57ed4db6a0510",
                  },
                ],
                _type: "block",
                style: "normal",
                _key: "2c5dca7eb0a1",
              },
              {
                children: [
                  {
                    _type: "span",
                    marks: [],
                    text: "The day you ovulate and 2 days before it are specifically referred to as ‘peak fertility’ days. Many medical professionals ",
                    _key: "d12ebfa5fd290",
                  },
                  {
                    _type: "span",
                    marks: ["b4410b8b35c1"],
                    text: "recommend having regular sex",
                    _key: "40c2669e1cac1",
                  },
                  {
                    _type: "span",
                    marks: [],
                    text: " throughout to maximise chances of conception. In fact, because the egg only remains viable 12-14 hours after release, it’s more important to have sex before to have sperm ready and available for fertilisation. Waiting until after could risk missing that window, especially when life gets in the way.",
                    _key: "40c2669e1cac2",
                  },
                ],
                _type: "block",
                style: "normal",
                _key: "9d1a69d440c0",
                markDefs: [
                  {
                    _type: "link",
                    href: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7164578/",
                    _key: "b4410b8b35c1",
                  },
                ],
              },
              {
                _key: "5593c16b5055",
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: [],
                    text: "",
                    _key: "cec85a7f49af0",
                  },
                ],
                _type: "block",
                style: "normal",
              },
              {
                markDefs: [
                  {
                    _type: "link",
                    href: "https://pubmed.ncbi.nlm.nih.gov/10362823/",
                    _key: "a245bc282324",
                  },
                ],
                children: [
                  {
                    _type: "span",
                    marks: [],
                    text: "It’s important to note that “getting pregnant” doesn’t just stop at fertilisation. Implantation of the fertilised egg into the uterine wall is arguably as crucial, and ",
                    _key: "5d281a2a34010",
                  },
                  {
                    marks: ["a245bc282324"],
                    text: "happens 8-10 days",
                    _key: "5d281a2a34011",
                    _type: "span",
                  },
                  {
                    _type: "span",
                    marks: [],
                    text: " after ovulation. This time is referred to as the dreaded ‘Two Week Wait’ (TWW) for couples trying to conceive naturally or through IVF. It can be an especially stressful time, so make sure to lean on your support system (that includes us 🤎)\n",
                    _key: "5d281a2a34012",
                  },
                ],
                _type: "block",
                style: "normal",
                _key: "02fd1ecfe54b",
              },
              {
                style: "normal",
                _key: "c4558aef016f",
                markDefs: [],
                children: [
                  {
                    _key: "87dd40e61d970",
                    _type: "span",
                    marks: [],
                    text: "Maintaining a consistent schedule of sexual activity can help ensure that you don’t miss that window. We know that juggling stress, schedules, and other responsibilities can be a challenge, so if you ever need to talk, we’re just a click away.",
                  },
                ],
                _type: "block",
              },
              {
                children: [
                  {
                    _type: "span",
                    marks: [],
                    text: "\n",
                    _key: "9cdca8ec75eb0",
                  },
                ],
                _type: "block",
                style: "normal",
                _key: "3e79ce9e3aba",
                markDefs: [],
              },
            ],
            relatedArticles: [
              {
                _ref: "0c155fe5-6457-4c18-9c5e-981a852e7d33",
                _type: "reference",
                _key: "7072a9bafc31",
              },
            ],
            _createdAt: "2023-09-28T13:27:11Z",
            _type: "article",
            _id: "8a001478-f2b6-43ce-97f4-5d11140def5d",
            slug: {
              current: "when-am-i-most-fertile-ovulation-window-explained",
              _type: "slug",
            },
            author: {
              _ref: "a97a52b1-a15c-43aa-924c-5324ff1f79fe",
              _type: "reference",
            },
            _rev: "rzmhfpQRA22PHrqvLL5m0c",
          },
          ms: 8,
        },
      });
    });
  // axios
  //   .get(
  //     ``
  //   )
  //   .then((response) => response.data)
  //   .then((response) => {
  //     if (response.err) {
  //       return callback({
  //         status: false,
  //       });
  //     }

  //     return callback({
  //       status: true,
  //       response,
  //     });
  //   });
};
export const get_guide_data = (data, callback) => {
  // return
  axios
    .post(
      `${base_url}${api.get_guide_detail_api}`,
      { slug: data }
      // {
      //   headers: {
      //     Authorization: token,
      //   },
      // }
      // `https://nao51hyy.api.sanity.io/vX/data/query/production?query=*%5B_type+%3D%3D+"article"+%26%26+_id+%3D%3D+${data._id}%5D%5B0%5D`
    )
    .then((response) => {
      // Handle successful response here
      return callback({
        status: true,
        response,
      });
    })
    .catch((error) => {
      return callback({
        status: true,

        response: {
          query:
            '*[_type == "article" && _id == "0c155fe5-6457-4c18-9c5e-981a852e7d33"][0]',
          result: {
            _rev: "Q2FCkGHH1q37qOS0Eq01WB",
            _type: "article",
            title: "What are 6 Signs of High Fertility in Women?",
            content: [
              {
                children: [
                  {
                    _type: "span",
                    marks: [],
                    text: "We talk a lot about infertility — what it is, when you should start thinking about it, the challenges it can bring, and the options you might have after. But a big part of family planning is also recognising the signs of high fertility, AKA figuring out if your body is a baby-making ",
                    _key: "fe3f5fb4ab630",
                  },
                  {
                    _key: "fe3f5fb4ab631",
                    _type: "span",
                    marks: ["em"],
                    text: "machine",
                  },
                  {
                    _type: "span",
                    marks: [],
                    text: " 👼🏼",
                    _key: "fe3f5fb4ab632",
                  },
                ],
                _type: "block",
                style: "normal",
                _key: "940dc618b0a3",
                markDefs: [],
              },
              {
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: [],
                    text: "For those looking to conceive, knowing these signs can help you understand how to stay in sync with your body. And if you’re not in the market for a baby right now, these symptoms can be an indicator that you should consult a doctor and update your birth control options.\n",
                    _key: "3bd050823ceb0",
                  },
                ],
                _type: "block",
                style: "normal",
                _key: "49c27300f2fb",
              },
              {
                children: [
                  {
                    _type: "span",
                    marks: [],
                    text: "Ready? Ok let’s go:",
                    _key: "24555d3ed50e0",
                  },
                ],
                _type: "block",
                style: "normal",
                _key: "5dec9b6bdb87",
                markDefs: [],
              },
              {
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: ["strong"],
                    text: "High Antral Follicle Count: ",
                    _key: "3d24cead4ffc0",
                  },
                  {
                    _key: "3d24cead4ffc1",
                    _type: "span",
                    marks: [],
                    text: "Ok ignore the big words. Your Antral Follicle Count is essentially the number of eggs you have reserved for baby-making. During a transvaginal ultrasound, your doctor can actually see the number of follicles in your ovaries.More follicles = high ovarian reserve = good stash of eggs ready for fertilisation!",
                  },
                ],
                level: 1,
                _type: "block",
                style: "normal",
                _key: "a3861963d388",
                listItem: "number",
              },
              {
                style: "normal",
                _key: "f8696740e5a9",
                listItem: "number",
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: ["strong"],
                    text: "Regular periods: ",
                    _key: "19faf524a08b0",
                  },
                  {
                    text: "If we haven’t said it enough, tracking your period is essential. Especially when you’re trying to conceive. Regular periods are basically like a built-in ovulation calendar, because you can easily pinpoint when you’re most likely to ovulate, invite a “friend” over 😉, and get busy!",
                    _key: "19faf524a08b1",
                    _type: "span",
                    marks: [],
                  },
                ],
                level: 1,
                _type: "block",
              },
              {
                children: [
                  {
                    text: "Changes in discharge: ",
                    _key: "748ff04f746e0",
                    _type: "span",
                    marks: ["strong"],
                  },
                  {
                    _type: "span",
                    marks: [],
                    text: "Ok hear us out. At different stages of your cycle, your discharge (AKA cervical mucus) probably looks different. Watch closely, because when it starts resembling the consistency and colour of egg whites that’s peak fertility, baby! The mucus helps sperm find their way to the egg, and lube your cervix up for some enjoyable sex.",
                    _key: "748ff04f746e1",
                  },
                ],
                level: 1,
                _type: "block",
                style: "normal",
                _key: "dea7df4315ec",
                listItem: "number",
                markDefs: [],
              },
              {
                _type: "block",
                style: "normal",
                _key: "3762cb6eb92d",
                listItem: "number",
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: ["strong"],
                    text: "Healthy levels of Anti-Mullerian Hormone (AMH): ",
                    _key: "26c806364fb40",
                  },
                  {
                    marks: [],
                    text: "Ok you might know the number, but do you know the health? AMH tests give us insights into your egg count and the health of your ovarian reserve. When your AMH levels are healthy, it means your ovaries are in good shape for fertility. It's like having the inside scoop on your egg reserve!",
                    _key: "e092f5a048de0",
                    _type: "span",
                  },
                ],
                level: 1,
              },
              {
                children: [
                  {
                    _key: "ee39636e4c390",
                    _type: "span",
                    marks: ["strong"],
                    text: "(Relatively) chill period symptoms: ",
                  },
                  {
                    _type: "span",
                    marks: [],
                    text: "Are you the kind of person who just pops a painkiller while your friends are dying from cramps? Well that’s a sign that your reproductive system is in a pretty healthy state. It's like your body's way of saying, \"Hey, keep doing what you’re doing!\"",
                    _key: "ee39636e4c391",
                  },
                ],
                level: 1,
                _type: "block",
                style: "normal",
                _key: "d0d5a05fd94c",
                listItem: "number",
                markDefs: [],
              },
              {
                _type: "block",
                style: "normal",
                _key: "5e464ba55cbb",
                listItem: "number",
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: ["strong"],
                    text: "Consistent Ovulation Tests: ",
                    _key: "421e4b74ce130",
                  },
                  {
                    _type: "span",
                    marks: [],
                    text: "Ok this might see obvious, but when you’re trying to conceive and taking monthly ovulation tests a positive result is… positive! Consistent results means your body is regularly ovulating healthily. \n",
                    _key: "421e4b74ce131",
                  },
                ],
                level: 1,
              },
              {
                _type: "block",
                style: "normal",
                _key: "30e62989d510",
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: [],
                    text: "Checked off all these boxes? Great! You’re ready to make a baby 😛",
                    _key: "b40ee2689bd30",
                  },
                ],
              },
              {
                _type: "block",
                style: "normal",
                _key: "b1eb446fcde1",
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: [],
                    text: "But remember that high fertility doesn’t guarantee success right away. Speaking to a healthcare professional can give you personalised guidance and even emotional support through your journey. We’re always with you, so don’t hesitate to reach out if you just need to talk.",
                    _key: "e0ed70dff70c0",
                  },
                ],
              },
              {
                style: "normal",
                _key: "b59448e2f1c8",
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: [],
                    text: "You’ve got this 💪🏼",
                    _key: "3d92ea4f2c510",
                  },
                ],
                _type: "block",
              },
            ],
            _updatedAt: "2023-09-30T12:07:43Z",
            _createdAt: "2023-09-28T13:11:08Z",
            coverImage: {
              hotspot: {
                height: 1,
                _type: "sanity.imageHotspot",
                width: 1,
                x: 0.5,
                y: 0.5,
              },
              _type: "image",
              asset: {
                _ref: "image-c61b24d6409de0f3556e59e87be50c4ba51420ae-564x706-jpg",
                _type: "reference",
              },
              crop: {
                right: 0,
                top: 0,
                left: 0,
                bottom: 0,
                _type: "sanity.imageCrop",
              },
            },
            subscriptionTier: "Free",
            slug: {
              current: "what-are-6-signs-of-high-fertility-in-women",
              _type: "slug",
            },
            date: "2023-06-26",
            author: {
              _ref: "a97a52b1-a15c-43aa-924c-5324ff1f79fe",
              _type: "reference",
            },
            description:
              "Ready to make a baby? (Or not) — Here’s what you should be looking for.",
            relatedArticles: [
              {
                _ref: "8a001478-f2b6-43ce-97f4-5d11140def5d",
                _type: "reference",
                _key: "07cb268e6ff3",
              },
            ],
            topic: [
              {
                _ref: "e1c60af8-e533-4106-b967-9d3e01c93d27",
                _type: "reference",
                _key: "631d50d821ba",
              },
            ],
            _id: "0c155fe5-6457-4c18-9c5e-981a852e7d33",
          },
          ms: 5,
        },
        response: {
          query:
            '*[_type == "article" && _id == "8a001478-f2b6-43ce-97f4-5d11140def5d"][0]',
          result: {
            coverImage: {
              _type: "image",
              asset: {
                _ref: "image-8c9de174a1fe78ffaa59c0778f65fcab99a5a81d-564x564-jpg",
                _type: "reference",
              },
            },
            description:
              "If your fertility was a puzzle (which it is), your fertile window is the reference picture. Following it can really speed things along! ",
            title: "When am I most fertile? Ovulation window explained.",
            _updatedAt: "2023-10-02T18:14:38Z",
            content: [
              {
                style: "normal",
                _key: "c24e7c98b5e0",
                markDefs: [],
                children: [
                  {
                    text: "If you’re anything like us, conception always seemed so simple. Sure, there’s stories in every show about infertility and adoption… But somehow we think if we just “do the deed” when we’re ready, it can magically happen for us.\n",
                    _key: "9b55c79f74570",
                    _type: "span",
                    marks: [],
                  },
                ],
                _type: "block",
              },
              {
                caption: "TEST",
                _key: "f0859a43e877",
                asset: {
                  _type: "reference",
                  _ref: "image-5a59ed039a49e66890ac034ca1b26a59229d6fa7-1040x333-png",
                },
                _type: "image",
                alt: "TEST",
              },
              {
                children: [
                  {
                    marks: [],
                    text: "Well, after months of reading and research, we’ve come to the conclusion that it ",
                    _key: "c53ed0ae612e0",
                    _type: "span",
                  },
                  {
                    _type: "span",
                    marks: ["em"],
                    text: "IS",
                    _key: "c53ed0ae612e1",
                  },
                  {
                    _type: "span",
                    marks: [],
                    text: " more difficult that was explained to us. ",
                    _key: "c53ed0ae612e2",
                  },
                  {
                    _type: "span",
                    marks: ["em"],
                    text: "So",
                    _key: "c53ed0ae612e3",
                  },
                  {
                    _type: "span",
                    marks: [],
                    text: " difficult, in fact, that we’ve built a whole company around demystifying it. We’re taking baby-making matters into our own hands, by talking about how you can optimise fertility.\n",
                    _key: "c53ed0ae612e4",
                  },
                ],
                _type: "block",
                style: "normal",
                _key: "636708e4b056",
                markDefs: [],
              },
              {
                children: [
                  {
                    _type: "span",
                    marks: ["strong"],
                    text: "What’s the Fertile Window?",
                    _key: "64955b5dd5c40",
                  },
                ],
                _type: "block",
                style: "normal",
                _key: "e41ce676660d",
                markDefs: [],
              },
              {
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: [],
                    text: "Your fertile window describes the period in the middle of your menstrual cycle when conception is most likely. For the average person, the fertile window lasts about 6 days — 1 day of ovulation (when the egg gets released from the ovary) and 5 days before that.",
                    _key: "d85de84709730",
                  },
                ],
                _type: "block",
                style: "normal",
                _key: "4016ff5669c7",
              },
              {
                style: "normal",
                _key: "88b2428b46e8",
                markDefs: [],
                children: [
                  {
                    _key: "11f391cf60b30",
                    _type: "span",
                    marks: [],
                    text: "Yep, ",
                  },
                  {
                    _key: "11f391cf60b31",
                    _type: "span",
                    marks: ["em"],
                    text: "only",
                  },
                  {
                    marks: [],
                    text: " 6 days. With all the fanfare and theatrics around our periods, ovulation should be an even bigger event, don’t you think? 🙄\n",
                    _key: "11f391cf60b32",
                    _type: "span",
                  },
                ],
                _type: "block",
              },
              {
                markDefs: [
                  {
                    href: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC188498/",
                    _key: "1a3e328c1d5f",
                    _type: "link",
                  },
                ],
                children: [
                  {
                    _type: "span",
                    marks: [],
                    text: "Having sex during your fertile window doesn’t ",
                    _key: "35fa6fc3066b0",
                  },
                  {
                    marks: ["em"],
                    text: "always",
                    _key: "35fa6fc3066b1",
                    _type: "span",
                  },
                  {
                    text: " mean you’ll get pregnant, it’s just the best time to try. Even if you get all the calculations and timing right, there’s only a ",
                    _key: "35fa6fc3066b2",
                    _type: "span",
                    marks: [],
                  },
                  {
                    _type: "span",
                    marks: ["1a3e328c1d5f"],
                    text: "25-30% chance of conceiving",
                    _key: "35fa6fc3066b3",
                  },
                  {
                    _type: "span",
                    marks: [],
                    text: " every month, and that tends to decrease as we get older.\n",
                    _key: "35fa6fc3066b4",
                  },
                ],
                _type: "block",
                style: "normal",
                _key: "bd8528cc8dcc",
              },
              {
                _type: "block",
                style: "normal",
                _key: "19d92a30cf6c",
                markDefs: [],
                children: [
                  {
                    text: "That’s because while sperm can survive in the uterus for up to 5 days, eggs must be fertilised 12-24 hours after release to remain viable. After that the window closes until your next cycle.",
                    _key: "f8ae7faa1d1e0",
                    _type: "span",
                    marks: [],
                  },
                ],
              },
              {
                markDefs: [],
                children: [
                  {
                    _key: "6ba8a586d5d50",
                    _type: "span",
                    marks: [],
                    text: "\n",
                  },
                ],
                _type: "block",
                style: "normal",
                _key: "a34f4914cf4e",
              },
              {
                style: "normal",
                _key: "1876fd369163",
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: ["strong"],
                    text: "When does Ovulation happen?",
                    _key: "1dca1c46eb320",
                  },
                ],
                _type: "block",
              },
              {
                _type: "block",
                style: "normal",
                _key: "05a13227517e",
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: [],
                    text: "Even if you have a 28-day cycle, ovulation doesn’t always fall midway on Day 14. It can vary from month to month depending on a bunch of hormonal factors. If your periods are irregular, your ovulation might occur over a wider range of days.",
                    _key: "1ff69d3f80630",
                  },
                ],
              },
              {
                markDefs: [],
                children: [
                  {
                    text: "That’s why it’s important to track your ovulation accurately. There are 5 ways you can track your fertility, and we’ve explained the A-Z of them right here.",
                    _key: "5417f84f94790",
                    _type: "span",
                    marks: [],
                  },
                ],
                _type: "block",
                style: "normal",
                _key: "3aa4ef039352",
              },
              {
                _type: "block",
                style: "normal",
                _key: "a349cc06cb2a",
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: [],
                    text: "\n",
                    _key: "dad940a8dfa40",
                  },
                ],
              },
              {
                _key: "f5cc9e82e00b",
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: ["strong"],
                    text: "Can I get pregnant any other time?",
                    _key: "a5670a7c4f3e0",
                  },
                ],
                _type: "block",
                style: "normal",
              },
              {
                markDefs: [
                  {
                    href: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8740731/",
                    _key: "2e8661ff4da1",
                    _type: "link",
                  },
                ],
                children: [
                  {
                    marks: [],
                    text: "Yes, including during your period. If you have an irregular or lengthier period, it indicates that ovulation is irregular too. Some months the egg could be released early, late or not at all. So it’s very possible to conceive even while menstruating. Studies show that women with irregular periods can ",
                    _key: "28a350b721670",
                    _type: "span",
                  },
                  {
                    text: "conceive anytime between 12-60 days",
                    _key: "50d026aa8fee1",
                    _type: "span",
                    marks: ["2e8661ff4da1"],
                  },
                  {
                    _type: "span",
                    marks: [],
                    text: " into their cycle.",
                    _key: "50d026aa8fee2",
                  },
                ],
                _type: "block",
                style: "normal",
                _key: "26ad48a91c55",
              },
              {
                _key: "32d7299b9b08",
                markDefs: [],
                children: [
                  {
                    marks: [],
                    text: "\n",
                    _key: "f1fc63a528f40",
                    _type: "span",
                  },
                ],
                _type: "block",
                style: "normal",
              },
              {
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: ["strong"],
                    text: "When are my best chances to get pregnant?",
                    _key: "57ed4db6a0510",
                  },
                ],
                _type: "block",
                style: "normal",
                _key: "2c5dca7eb0a1",
              },
              {
                children: [
                  {
                    _type: "span",
                    marks: [],
                    text: "The day you ovulate and 2 days before it are specifically referred to as ‘peak fertility’ days. Many medical professionals ",
                    _key: "d12ebfa5fd290",
                  },
                  {
                    _type: "span",
                    marks: ["b4410b8b35c1"],
                    text: "recommend having regular sex",
                    _key: "40c2669e1cac1",
                  },
                  {
                    _type: "span",
                    marks: [],
                    text: " throughout to maximise chances of conception. In fact, because the egg only remains viable 12-14 hours after release, it’s more important to have sex before to have sperm ready and available for fertilisation. Waiting until after could risk missing that window, especially when life gets in the way.",
                    _key: "40c2669e1cac2",
                  },
                ],
                _type: "block",
                style: "normal",
                _key: "9d1a69d440c0",
                markDefs: [
                  {
                    _type: "link",
                    href: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7164578/",
                    _key: "b4410b8b35c1",
                  },
                ],
              },
              {
                _key: "5593c16b5055",
                markDefs: [],
                children: [
                  {
                    _type: "span",
                    marks: [],
                    text: "",
                    _key: "cec85a7f49af0",
                  },
                ],
                _type: "block",
                style: "normal",
              },
              {
                markDefs: [
                  {
                    _type: "link",
                    href: "https://pubmed.ncbi.nlm.nih.gov/10362823/",
                    _key: "a245bc282324",
                  },
                ],
                children: [
                  {
                    _type: "span",
                    marks: [],
                    text: "It’s important to note that “getting pregnant” doesn’t just stop at fertilisation. Implantation of the fertilised egg into the uterine wall is arguably as crucial, and ",
                    _key: "5d281a2a34010",
                  },
                  {
                    marks: ["a245bc282324"],
                    text: "happens 8-10 days",
                    _key: "5d281a2a34011",
                    _type: "span",
                  },
                  {
                    _type: "span",
                    marks: [],
                    text: " after ovulation. This time is referred to as the dreaded ‘Two Week Wait’ (TWW) for couples trying to conceive naturally or through IVF. It can be an especially stressful time, so make sure to lean on your support system (that includes us 🤎)\n",
                    _key: "5d281a2a34012",
                  },
                ],
                _type: "block",
                style: "normal",
                _key: "02fd1ecfe54b",
              },
              {
                style: "normal",
                _key: "c4558aef016f",
                markDefs: [],
                children: [
                  {
                    _key: "87dd40e61d970",
                    _type: "span",
                    marks: [],
                    text: "Maintaining a consistent schedule of sexual activity can help ensure that you don’t miss that window. We know that juggling stress, schedules, and other responsibilities can be a challenge, so if you ever need to talk, we’re just a click away.",
                  },
                ],
                _type: "block",
              },
              {
                children: [
                  {
                    _type: "span",
                    marks: [],
                    text: "\n",
                    _key: "9cdca8ec75eb0",
                  },
                ],
                _type: "block",
                style: "normal",
                _key: "3e79ce9e3aba",
                markDefs: [],
              },
            ],
            relatedArticles: [
              {
                _ref: "0c155fe5-6457-4c18-9c5e-981a852e7d33",
                _type: "reference",
                _key: "7072a9bafc31",
              },
            ],
            _createdAt: "2023-09-28T13:27:11Z",
            _type: "article",
            _id: "8a001478-f2b6-43ce-97f4-5d11140def5d",
            slug: {
              current: "when-am-i-most-fertile-ovulation-window-explained",
              _type: "slug",
            },
            author: {
              _ref: "a97a52b1-a15c-43aa-924c-5324ff1f79fe",
              _type: "reference",
            },
            _rev: "rzmhfpQRA22PHrqvLL5m0c",
          },
          ms: 8,
        },
      });
    });
  // axios
  //   .get(
  //     ``
  //   )
  //   .then((response) => response.data)
  //   .then((response) => {
  //     if (response.err) {
  //       return callback({
  //         status: false,
  //       });
  //     }

  //     return callback({
  //       status: true,
  //       response,
  //     });
  //   });
};

export const EmailInvoice = (obj, token, callback) => {
  // return;
  try {
    axios
      .post(
        `${base_url}${api.email_invoice}`,
        { ...obj },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};

export const SendOtp = (pagevalues, callback) => {
  // return
  try {
    axios
      .post(
        `${base_url}${api.send_otp}`,
        {
          phone:
            pagevalues.pagevalues.phone.replace(/ /g, "") ||
            pagevalues.pagevalues.email,
        },
        {}
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};

export const ResendOtp = (pagevalues, callback) => {
  // return
  try {
    axios
      .post(
        `${base_url}${api.re_send_otp}`,
        {
          phone:
            pagevalues.pagevalues.phone.replace(/ /g, "") ||
            pagevalues.pagevalues.email,
        },
        {}
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};

export const SendOtpExistingUser = (pagevalues, callback) => {
  // return
  try {
    axios
      .post(
        `${base_url}${api.send_otp}`,
        {
          phone: pagevalues.pagevalues,
        },
        {}
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const GetOrderSinglePage = (callback) => {
  // return
  try {
    axios
      .post(`https://api.arva.health/db/completed-orders`, {}, {})
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const GetUsersSinglePage = (callback) => {
  // return
  try {
    axios
      .post(`https://api.arva.health/db/users`, {}, {})
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const CheckPincode = (pagevalues, callback) => {
  // return;
  try {
    axios
      .post(
        `${base_url}${api.check_pincode}`,
        { pincode: pagevalues.pagevalues.zipcode },
        {}
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const CheckPincodeV2 = (zipcode, callback) => {
  // return;

  try {
    axios
      .post(`${base_url}${api.check_pincode}`, { pincode: zipcode }, {})
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const CheckPincodeV3 = (zipcode, callback) => {
  // return;

  try {
    axios
      .post(
        `https://labs.arva.health/thyrocare/pincode`,
        { pincode: zipcode },
        {}
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const CheckPincodeLog = (data1) => {
  try {
    let data = JSON.stringify({
      type: 1,
      slots: data1.slots,
      pincode: data1.zipcode,
      slotDate: data1.slotDate,
      phone: data1.phone,
      admin: data1?.admin || "",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${base_url}${api.slotslogshistory}`,
      // url: "https://script.google.com/macros/s/AKfycbwuGv69b-4rN3p7GRadqqY6eWdtwjQm6850TAgTEoVTxKCodelzRWzCWtw1QqC8ttsk/exec",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {})
      .catch((error) => {});
  } catch {}
};
export const CheckPincodeAdmin = (value, token, callback) => {
  // return;
  try {
    axios
      .post(
        `${base_url}${api.check_pincode_admin}`,
        {
          pincode: value,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const CheckChooseSlot = (data, callback) => {
  // return;
  try {
    axios
      .post(
        `${base_url}${api.get_appointnment}`,
        {
          date: date_formatter(new Date(data.date), "year_long-month-date"),
          product_id: data.product_id,
          pincode: data.zipcode,
        },
        {}
      )
      .then((response) => response.data)
      .then((response) => {
        if (data.zipcode) {
          CheckPincodeLog({
            ...data,
            slotDate: date_formatter(
              new Date(data.date),
              "year_long-month-date"
            ),
            slots: response,
          });
        }
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const DirectCheckChooseSlot = (data, callback) => {
  // return;
  try {
    axios
      .post(
        `${base_url}${api.get_appointnment}`,
        {
          // date: new Date(data.date).toString(),
          date: date_formatter(new Date(data.date), "year_long-month-date"),
          product_id: data.product_id,
          pincode: data.zipcode,
          all: data?.all,
        },
        {}
      )
      .then((response) => response.data)
      .then((response) => {
        if (data.zipcode) {
          CheckPincodeLog({
            ...data,
            slotDate: date_formatter(
              new Date(data.date),
              "year_long-month-date"
            ),
            slots: response,
          });
        }
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }
        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const EditIntake = (data, token, callback) => {
  // return;
  try {
    axios
      .post(
        `${base_url}${api.edit_intake}`,
        { tracking_arva: data.tracking_arva },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const AddUpdateGoal = (data, token, callback) => {
  // return;
  try {
    axios
      .post(
        `${base_url}${api.add_update_goal}`,
        { tracking_arva: data.tracking_arva },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const EditNotification = (data, token, callback) => {
  // return
  try {
    axios
      .post(
        `${base_url}${api.edit_notification}`,
        {
          order_updates: data.order_updates,
          reminders: data.reminders,
          content_updates: data.content_updates,
          feature_updates: data.feature_updates,
          pause_all: data.pause_all,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const SendTimeSlot = (data, token, callback) => {
  // return
  try {
    axios
      .post(
        `${base_url}${api.add_order_date}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const SendTimeSlotAdmin = (data, token, callback) => {
  // return
  try {
    axios
      .post(
        `${base_url}${api.book_slot_admin}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const SendOrderReminder = (data, token, callback) => {
  // return
  try {
    axios
      .post(
        `${base_url}${api.order_reminder}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const RebookTimeSlotAdmin = (data, token, callback) => {
  // return
  try {
    axios
      .post(
        `${base_url}${api.re_book_slot_admin}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const SendReminderDate = (data, token, callback) => {
  // return
  try {
    axios
      .post(
        `${base_url}${api.add_reminder_date}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
// export const EditAdminTestList = (data, token, callback) => {
//   // return
//   try {
//     axios
//       .post(
//         `${base_url}${api.add_order_date}`,
//         {
//           ...data,
//         },
//         {
//           headers: {
//             Authorization: token,
//           },
//         }
//       )
//       .then((response) => response.data)
//       .then((response) => {
//         if (response.err) {
//           return callback({
//             status: false,
//             response: response.err,
//             // response: "Network Err or Try Again",
//           });
//         }

//         return callback({
//           status: true,
//           response,
//         });
//       })
//       .catch(() => {
//         return callback({
//           status: false,
//           response: "Network Err or Try Again",
//         });
//       });
//   } catch {
//     return callback({
//       status: false,
//       response: "Network Err or Try Again",
//     });
//   }
// };
export const GetAdminOrderList = (data, token, callback) => {
  // return
  try {
    axios
      .post(
        `${base_url}${api.get_admin_order_list}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const CancelOrderApi = (data, token, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.cancelorder}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const GetSanity = (callback) => {
  // return
  try {
    axios
      .post(`${base_url}${api.get_sanity}`, {}, {})
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const GetSanityProduct = (callback) => {
  // return
  try {
    axios
      .post(`${base_url}${api.get_sanity_product}`, {}, {})
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const EditAdminOrderIntake = (data, token, callback) => {
  // return
  try {
    axios
      .post(
        `${base_url}${api.edit_admin_order_intake}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const EditAdminOrderTest = (data, token, callback) => {
  // return
  try {
    axios
      .post(
        `${base_url}${api.edit_orders_biomarkers}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const AdminUserList = (token, callback) => {
  // return
  try {
    axios
      .post(
        `${base_url}${api.all_users}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const AddAdminUser = (data, token, callback) => {
  // return
  try {
    axios
      .post(
        `${base_url}${api.add_users}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const EditAdminUser = (data, token, callback) => {
  // return
  try {
    axios
      .post(
        `${base_url}${api.edit_users}`,
        { ...data },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const CoachingAdminSuccess = (data, token, callback) => {
  // return
  try {
    axios
      .post(
        `${base_url}${api.coaching_success}`,
        { ...data },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const CoachingAdminFail = (data, token, callback) => {
  // return
  try {
    axios
      .post(
        `${base_url}${api.coaching_failed}`,
        { ...data },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const AddEditDrData = (data, token, callback) => {
  // return
  try {
    axios
      .post(
        `${base_url}${api.edit_followup}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const EditOrdersAppoint = (data, token, callback) => {
  // return
  try {
    axios
      .post(
        `${base_url}${api.editordersappoint}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const DrAssign = (data, token, callback) => {
  // return
  try {
    axios
      .post(
        `${base_url}${api.drassign}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};

export const IntakeApi = (data, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.record_intake}`,
        {
          data: data.pagevalues,
          cust_id: data.cust_id,
          phone: data.phone.replaceAll(" ", ""),
        },
        {}
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
            // response: "Network Err or Try Again",
          });
        }

        return callback({
          status: true,
          response,
        });
      })
      .catch(() => {
        return callback({
          status: false,
          response: "Network Err or Try Again",
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};
export const CheckTokenUser = ({ token }, callback) => {
  try {
    axios
      .post(
        `${base_url}${api.check_tokenuser}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.err) {
          return callback({
            status: false,
            response: response.err,
          });
        }

        return callback({
          status: true,
          response,
        });
      });
  } catch {
    return callback({
      status: false,
      response: "Network Err or Try Again",
    });
  }
};

export const googleLogin = (idToken, callback) => {
  // return
  axios
    .post(`${base_url}${api.googlelogin}`, {
      google_token: idToken,
    })
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response,
      });
    });
};
export const googleLoginApp = (idToken, callback) => {
  // return
  axios
    .post(`${base_url}${api.googleloginapp}`, {
      google_token: idToken,
    })
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response,
      });
    });
};
export const googleLoginAdmin = (idToken, callback) => {
  // return
  axios
    .post(`${base_url}${api.googlelogin_admin}`, {
      google_token: idToken,
    })
    .then((response) => response.data)
    .then((response) => {
      if (response.err) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        response,
      });
    });
};

export const getClinics = (token, cb) => {
  let data = "";

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: "https://api.arva.health/sanity/clinics",
    headers: {
      authorization: token,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      cb(response.data);
    })
    .catch((error) => {});
};
