import React, { useEffect, useState } from "react";
import { CommonNavV3 } from "./CommonNavV3";
import { CommonFooterV3 } from "./CommonFooterV3";
import { MultiSelectV3 } from "./MultiSelectV3";
import { RangeShower } from "./RangeShower";
import { useNavigate, useParams } from "react-router-dom";
import { EditAddressV3 } from "./EditAddressV3";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

export const ChooseLabs = ({ data, booking_data, LabLoading }) => {
  const nav = useNavigate();
  const store = useSelector((state) => state);
  const { periodType, pageType } = useParams();
  const dispatch = useDispatch();
  const [EditAddressModal, setEditAddressModal] = useState(false);
  const [SelectLab, setSelectLab] = useState({});

  useEffect(() => {
    if (data?.length) {
      setSelectLab({});
    }
  }, [data?.length]);

  useEffect(() => {
    if (store?.checktoken_data?.selectedlab?.labName) {
      setSelectLab(store?.checktoken_data?.selectedlab);
    }
  }, [store?.checktoken_data?.selectedlab?.labName]);

  useEffect(() => {
    if (booking_data?.product_id) {
      if (
        periodType == "period-now" &&
        booking_data?.product_id?.toLowerCase() == "ft02"
      ) {
        dispatch({
          type: "setCheckToken",
          payload: {
            ...store.checktoken_data,
            selectedlab: {
              menstralstate: "Yes, I’m on my period now",
            },
          },
        });
        return;
      }
      if (!store?.checktoken_data?.selectedlab?.menstralstate) {
        nav("/bookingslot/period-question/question");
      }
    }
  }, [periodType, booking_data]);

  return (
    <>
      {/* <CommonNavV3
        {...{
          goback: () => {
            nav(-1);
            // nav(`/bookingslot/period-question/question`);
          },
        }}
      /> */}
      <div className="choose_lab_section">
        {EditAddressModal ? (
          <EditAddressV3
            {...{
              EditAddressModal,
              setEditAddressModal,
            }}
          />
        ) : (
          ""
        )}
        <RangeShower
          {...{
            list: 4,
            current: 2,
          }}
        />
        <div className="choose_lab_title">
          Choose your preferred diagnostic lab
        </div>
        <div className="choose_lab_desc">
          Showing availability for{" "}
          {store?.checktoken_data?.customer?.address?.pin_code}{" "}
          <span
            onClick={() => {
              setEditAddressModal(true);
            }}
          >
            Edit
          </span>
        </div>
        <div className="lab_list">
          {/* {false ? ( */}
          {data?.length ? (
            data?.map((el, i) => (
              <div
                className={
                  "lab_box" +
                  ((SelectLab.labName == el.labName ? " lab_box_select" : "") +
                    (!el.status == "Available" ? " unav_lav" : ""))
                }
                onClick={() => {
                  if (el.status == "Available") {
                    setSelectLab(el);
                  }
                }}
              >
                <>
                  {SelectLab.labName == el.labName ? (
                    <div className="lab_box_select">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM13.707 8.707C13.8892 8.5184 13.99 8.2658 13.9877 8.0036C13.9854 7.7414 13.8802 7.49059 13.6948 7.30518C13.5094 7.11977 13.2586 7.0146 12.9964 7.01233C12.7342 7.01005 12.4816 7.11084 12.293 7.293L9 10.586L7.707 9.293C7.5184 9.11084 7.2658 9.01005 7.0036 9.01233C6.7414 9.0146 6.49059 9.11977 6.30518 9.30518C6.11977 9.49059 6.0146 9.7414 6.01233 10.0036C6.01005 10.2658 6.11084 10.5184 6.293 10.707L8.293 12.707C8.48053 12.8945 8.73484 12.9998 9 12.9998C9.26516 12.9998 9.51947 12.8945 9.707 12.707L13.707 8.707Z"
                          fill="#5F1D1B"
                        />
                      </svg>
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className="lab_box_image"
                    style={{
                      backgroundImage: `url(${el.labPhoto})`,
                    }}
                  ></div>
                  <div
                    className={
                      "lab_box_status" +
                      (el.status == "Available" ? " lab_box_status_active" : "")
                    }
                  >
                    {el.status}
                  </div>
                </>
              </div>
            ))
          ) : LabLoading ? (
            // true ? (
            [1, 2]?.map((el, i) => (
              <div className={"lab_box lab_loading"}>
                <div className="lab_box_skel_image">
                  <Skeleton height="100%" width="100%" />
                </div>
                <div className="lab_box_skel_text">
                  <Skeleton height="100%" width="100%" />
                </div>
              </div>
            ))
          ) : (
            <div style={{ textAlign: "center" }}>
              No labs are currently available.
              <br /> Please contact the admin for further instructions.
            </div>
          )}
        </div>
      </div>
      <CommonFooterV3
        {...{
          name: "Select lab",
          onclick: () => {
            if (!SelectLab?.labName) {
              return;
            }
            if (
              SelectLab.labName == store?.checktoken_data?.selectedlab?.labName
            ) {
              dispatch({
                type: "setCheckToken",
                payload: {
                  ...store.checktoken_data,
                  selectedlab: {
                    ...store.checktoken_data.selectedlab,
                    ...SelectLab,
                  },
                },
              });
            }
            if (
              SelectLab.labName != store?.checktoken_data?.selectedlab?.labName
            ) {
              dispatch({
                type: "setCheckToken",
                payload: {
                  ...store.checktoken_data,
                  selectedlab: {
                    menstralstate:
                      store.checktoken_data.selectedlab.menstralstate,
                    ...SelectLab,
                  },
                },
              });
            }
            nav(`/bookingslot/${periodType}/slots`);
            // if (order1 && order2) {
            // } else {
            //   nav(`/bookingslot/period-now/labs`);
            // }
          },
        }}
      />
    </>
  );
};
